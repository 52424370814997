import React, { useEffect, useState } from 'react'
import { Box, Columns, Button, Tag, Image, Modal, Heading, Table, Dropdown } from 'react-bulma-components/dist';
import { Product, Order, sendMail } from '../utilities/classes'
import { getCurrentCustomer } from '../utilities/Login'
import { standardDeliveryTime, freeShippingThreshold, standardPaidShippingPrice, isCustomPostPaid_AllProducts, clientName, customPricePolicyLine } from '../clientInfo/clientInfo'
import { imageNamed, PRODUCT_TYPE, uuidv4 } from '../utilities/utils'
import { clientWebsite, clientLogoUrl, clientEmailFromForOrder } from '../clientInfo/clientInfo'


import { dateToDateString } from '../utilities/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast'
import PaymentWrapper from './PaymentWrapper'
import PaypalPaymentPage from './PaypalPaymentPage'
import ColumnAux from './ColumnAux'

import Logo from '../assets/logoTailorwise.svg'
// import Logo from '../assets/logoClient.png'
// import Logo from '../assets/logoClientNav.png'
// import Logo from '../assets/logoClientMenu.png'

import OrderActionForm from './OrderActionForm'

const OrderModal = (props) => {

    let [order, setOrder] = useState(props.order)
    let [showOrderAction, setShowOrderAction] = useState(false)

    let [products, setProducts] = useState(props.isNewOrder ? (props.order.products ? props.order.products : []) : [])

    const isPaying = () => {
        let isP = false
        if (props.isNewOrder) {
            if (order.price) {
                if (order.price > 0) {
                    isP = true
                }
            }
        }
        return isP
    }

    let [paying, setPaying] = useState(isPaying())
    let [fetchProducts, setFetchProducts] = useState(true)
    let [showPaymentSuccess, setShowPaymentSuccess] = useState(false)



    useEffect(() => {

        if (!props.isNewOrder) {
            console.log(`GOT ORDER ${order.orderNo}`)
            let cust = getCurrentCustomer()
            if (fetchProducts) {
                Product.getAllForOrder(order, cust, (succ, products, errMsg) => {
                    if (succ) {
                        setProducts(products)
                    } else {
                        cogoToast.warn('Could Not Fetch Products. Check internet connection.')
                        console.log('Could Not Fetch Products : ' + errMsg)
                    }
                })
                setFetchProducts(false)
            }
        }


    }, [order, fetchProducts, props.isNewOrder])

    // let _total = 0

    const cartRows = () => {
        let i = 0
        // _total = 0


        if (products !== []) {


            let prods = [...products]

            let rows = prods.map(st => {
                i = i++
                // _total += st.price
                return <tr key={i + 'cartrow'}>
                    <td>{st.type} <div className='type50'> {st.isBespoke ? 'BESPOKE ·' : 'MADE TO ORDER ·'} {st.description} </div>
                        <div className='cartItemContainer'>
                            {st.imageUrl ?
                                <div className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                                    <Image className='is-clipped ' src={st.imageUrl} size='1by1' />
                                </div>
                                : null}

                            {st.fabricImage ?
                                <div className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                                    <Image className='is-clipped ' src={st.fabricImage} size='1by1' />
                                </div>
                                : null}
                        </div>
                    </td>
                    {/* <td>1</td> */}
                    <td className='has-text-right'>{order.currency}{st.price}</td>
                </tr>
            })

            // props.updateFinalPrice(_total)
            return rows
        }

        // props.updateFinalPrice(_total)
        return null

    }


    const shippingRows = () => {

        if (order.shipping) {
            // let totalProductPrice = 0
            // products.forEach(pt => {
            //     totalProductPrice += pt.price
            // })

            // if (totalProductPrice > freeShippingThreshold) {

            return (
                <tr key={'shippingRow2526'}>
                    <td> Shipping Charges</td>
                    <td className='has-text-right'>{order.currency}{order.shipping}</td>
                </tr>
            )

            // }
            // return null
        }

    }

    const cartDetails = () => {
        return <Columns>
            <Columns.Column>
                <Table className='is-hoverable type74 fadeIn '>
                    <thead >
                        <tr >
                            <td className='has-text-grey-light is-size-7'>Product</td>
                            {/* <td className='has-text-grey-light is-size-7'>Count</td> */}
                            <td className='has-text-grey-light has-text-right is-size-7'>Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        {cartRows()}
                        {shippingRows()}
                    </tbody>
                    <tfoot>
                        <tr>

                        </tr>
                        <tr>
                            <th></th>
                            {/* <th></th> */}
                            <th className='has-text-right'> {order.currency}{order.price}</th>
                        </tr>
                    </tfoot>
                </Table>
                {/* {props.measures.length > 0 ?
                    <Box className=' is-shadowless'>
                        <div className='is-size-7  has-text-semibold fadeIn'>  <FontAwesomeIcon className='has-text-info' icon={faCheck} /> &nbsp; &nbsp; Measurements Added. Current Measurent will be attached to order. </div>
                    </Box>
                    : null
                } */}


                {/* <br />
                <Box className='fadeIn'>
                    <Heading className='' size={6}>Shop With Confidence</Heading>
                    <div className='type60 '>Every order is carefuly reviewed at { clientName }. We will study the style and the measurements provided by you. If we have any suggestions or queries we will reach out to you to make sure your Apparels are just perfect.</div>
                </Box> */}


            </Columns.Column>
            <Columns.Column size={1}>
            </Columns.Column>
        </Columns >
    }



    const sendPaymentMail = (paymentId, status, amountInHundred, currency, gateway) => {

        let amount = amountInHundred

        let idType = "PaypalGeneratedId"


        if (gateway === "STRIPE") {
            amount = amountInHundred / 100
            idType = "PaymentId"
        }

        let ord = { ...order }


        let paymentInfo = `${gateway}. ${idType} ${paymentId}. Amount ${amount} ${currency}. Status ${status}.`
        ord.paymentInfo = paymentInfo

        let header = {
            "logoUrl": clientLogoUrl,
            "orderNo": ord.orderNo,
            "clientName": ord.customerName,
            "shippingDuration": standardDeliveryTime
        }

        let productsInfo = []
        products.forEach((prod) => {
            let prodInfo = {
                "imageUrl": prod.imageUrl ?? "",
                "type": prod.type,
                "name": prod.name,
                "description": ((prod.isBespoke ? 'BESPOKE · ' : 'MADE TO ORDER · ') + prod.description)
            }
            productsInfo.push(prodInfo)
        })

        let measureAndDelivery = {
            "measuresDescription": "",
            "clientName": ord.customerName,
            "deliveryAddress": ord.deliveryAddress
        }

        let measurements = ord.conciergeMeasurements
        let measureDescription = ""



        let cust = getCurrentCustomer()
        let existingMeasure = cust.conciergeMeasurements ?? null

        if (ord.conciergeMeasurements === null || !ord.conciergeMeasurements.length) {
            if (existingMeasure !== null) {
                measurements = [{ measures: existingMeasure, description: "", key: "sjbskjjbjbskjiui34" }]
                measureDescription = "Measurement Skipped - Last saved measurements attached : "
            }
        }



        console.log("=====MEASURESMENTS ARE=====")
        console.log(measurements)

        if (measurements.length) {
            let measuremnt = measurements[0]
            let allMeasures = measuremnt.measures

            console.log("=====ALL MEASURES=====")
            console.log(allMeasures)

            let keysArr = Object.keys(allMeasures)
            console.log("=====KEYS ARR=====")
            console.log(keysArr)

            let subDesc = ""
            keysArr.map((kk) => {
                if (kk === 'AGE' || kk === 'Age' || kk === 'age') {
                    return null
                }
                if (kk === 'WEIGHT' || kk === 'Weight' || kk === 'weight') {
                    return null
                }
                let str = `\n • ${kk} = ${allMeasures[kk].value} ${allMeasures[kk].unit} `
                subDesc += str
                return null
            });

            measureDescription = `${measureDescription} \n ${subDesc}`

            measureAndDelivery.measuresDescription += measureDescription
        } else {
            measureAndDelivery.measuresDescription = "Measurement Skipped"
        }

        let paymentSummary = {
            "totalPayment": amount,
            "currency": currency,
            "paymentInfo": paymentInfo
        }

        let footer = {
            "website": clientWebsite,
        }

        let fromEmail = clientEmailFromForOrder
        let toEmail = ord.customerEmail
        let subject = `Order Confirmation. Payment Success - ${ord.orderNo}`
        let mailTitle = "Order Confirmation"


        sendMail(header, productsInfo, measureAndDelivery, paymentSummary, footer, fromEmail, toEmail, subject, mailTitle)
    }



    const paymentDone = (paymentId, status, amountInHundred, currency, gateway) => {
        //
        sendPaymentMail(paymentId, status, amountInHundred, currency, gateway)

        let amount = amountInHundred
        let idType = "PaypalGeneratedId"

        if (gateway === "STRIPE") {
            amount = amountInHundred / 100
            idType = "PaymentId"
        }

        let ord = Order.copyFrom(order)
        ord.isPaid = true

        let paymentInfo = `${gateway}. ${idType} ${paymentId}. Amount ${amount} ${currency}. Status ${status}.`
        ord.paymentInfo = paymentInfo

        // Update Order

        ord.update((succ, errMsg) => {
            if (succ) {
                togglePaymentSuccess()
                setOrder(ord)
                if (props.recentPaymentUpdated) {
                    props.recentPaymentUpdated()
                }
                if (!props.isNewOrder) {
                    props.orderUpdated(ord)
                }

            } else {
                console.log(errMsg)
            }
        })
        console.log('Order Payment Info Updated')

        hidePayingScreen()
    }

    const payNow = () => {
        if (order.isPaid !== true) {
            setPaying(true)
        }
    }

    const hidePayingScreen = () => {
        setPaying(false)
    }




    const paymentModal = () => {

        // console.log('ORDER IS ')
        // console.log(state.order)



        return (
            <Modal show={paying} closeOnBlur={false} closeOnEsc={false} showClose={true} onClose={() => hidePayingScreen()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered has-text-centered'>
                            <img className='is-centered' src={clientLogoUrl} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" />
                            <br />
                            <br />

                            <PaymentWrapper order={order} finalPrice={order.price} paymentDone={paymentDone} />
                            {/* <PaypalPaymentPage order={order} finalPrice={order.price} paymentDone={paymentDone} /> */}

                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>
        )
    }


    const orderUpdated = (updatedOrder) => {
        setOrder(updatedOrder)
        setShowOrderAction(false)
        props.orderUpdated(updatedOrder)
    }


    const orderActionModal = () => {

        // console.log('ORDER IS ')
        // console.log(state.order)

        return (
            <Modal show={showOrderAction} closeOnBlur={false} closeOnEsc={false} showClose={true} onClose={() => hideOrderAction()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <ColumnAux isMiddle={true} size={10} className='  '>
                            {/* <PaymentWrapper order={order} finalPrice={order.price} paymentDone={paymentDone} /> */}
                            <OrderActionForm order={order} orderUpdated={orderUpdated} />

                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>
        )
    }


    const showOrderActionPressed = () => {
        setShowOrderAction(true)
    }

    const hideOrderAction = () => {
        setShowOrderAction(false)
    }

    const actionPressed = (e) => {
        cogoToast.info(e)
        console.log('Order Action Pressed')
        console.log(e)

        showOrderActionPressed()
    }



    const orderModal = (order) => {

        console.log(`ORDER MODAL FIRED WITH ${order.orderNo}`)

        if (showOrderAction) {
            return orderActionModal()
        }


        if (paying) {
            return paymentModal()
        }

        if (showPaymentSuccess) {
            return paymentSuccessModal()

        }

        let showPayNow = true

        if (order.isPaid || order.price <= 0) {
            showPayNow = false 
        }



        return (
            <Modal show={true} closeOnBlur={true} showClose={true} onClose={props.closed} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn'>
                        {props.isAdmin ? <Button className='is-pulled-right marginBottom20 is-light is-info is-small' onClick={actionPressed} color="light" > Edit Actions </Button> : null}
                        {/* <Dropdown right={true} className=' is-pulled-right marginBottom20' value={null} onChange={actionPressed} color="light" label={'Actions'}>
                            <Dropdown.Item value="markDelivered" >
                                Mark Delivered
                                </Dropdown.Item>
                            <Dropdown.Item value="markPending">
                                Mark Pending
                                </Dropdown.Item>
                            <Dropdown.Item value="editDisplayNote">
                                Add/Edit Display Note
                                </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item value="cancelOrRefund">
                                Cancel/Refund Order
                                </Dropdown.Item>
                        </Dropdown> */}
                        <div>
                            <Heading size={6}> ORDER #{order.orderNo}</Heading>
                            {order.isCancelled ? <Heading size={6} className='has-text-danger'> CANCELLED </Heading> : null}
                        </div>
                        <br />
                        <div>
                            <Columns className='is-mobile is-size-7'>
                                <Columns.Column className=''>
                                    <p className='has-text-grey  marginBottom5'> Booking </p>
                                    <p>{order.bookingDate ? dateToDateString(order.bookingDate) : null}</p>
                                </Columns.Column>
                                <Columns.Column className=''>
                                    <p className='has-text-grey  marginBottom5'> Exp. Delivery </p>
                                    <p>{order.deliveryDate ? dateToDateString(order.deliveryDate) : null}</p>
                                </Columns.Column>
                                <Columns.Column className=''>
                                    <p className='has-text-grey  marginBottom5'> Status </p>
                                    <Tag color="light" >{order.status}</Tag>
                                </Columns.Column>
                            </Columns>
                            <br />
                        </div>



                        {order.conciergeDisplayNote ?
                            <Box className=" is-size-7 has-text-info " > <p> UPDATE </p> <p> {order.conciergeDisplayNote} </p> </Box>
                            :
                            null
                        }




                        <div>
                            <p className='has-text-grey  marginBottom5'> Payment </p>
                            <p className='has-text-grey-light  marginBottom5'> {isCustomPostPaid_AllProducts ? customPricePolicyLine  : null} </p>

                            {order.isPaid ? <Tag className="" >Paid {order.currency + order.price + ''}</Tag> : <Tag className="is-warning is-light" >Pending {order.currency + order.price + ''}</Tag>}
                            {order.isRefunded ? <Tag className=" is-info is-light" >Refunded {order.currency + order.refundedAmount + ''}</Tag> : null}

                            &nbsp;
                            {
                                props.isAdmin ? null :
                                    showPayNow ? <Button className='is-small is-black' onClick={() => { payNow() }} > Pay Now </Button> : null

                            }
                            <br />
                            <br />
                        </div>
                        <div>
                            {cartDetails()}
                            <br />
                        </div>

                        <br />
                        <div>
                            <p className='has-text-grey  marginBottom5'> Delivery Address </p>
                            <p className="type60"> {order.deliveryAddress} </p>
                            <br />
                        </div>

                    </Box>
                </Modal.Content >
            </Modal >
        )
    }


    const togglePaymentSuccess = () => {
        setShowPaymentSuccess(!showPaymentSuccess)
    }

    const paymentSuccessModal = () => {
        return (
            <Modal show={showPaymentSuccess} closeOnBlur={true} showClose={true} onClose={togglePaymentSuccess} >
                <Modal.Content className=''>
                    <Box className='is-radiusless  fadeIn'>
                        <ColumnAux isMiddle={true} size={10} className='has-background-white is-centered  marginTop10'>
                            {/* <img className='is-centered' src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" /> */}
                            {/* <br /> */}
                            <div size={5} className=' avenir has-text-weight-semibold  has-text-success'> That Looks Good!  </div>
                            <p className='type60 has-text-weight-light has-text-grey '> We will soon confirm your order. And carry out this gist of tasks.</p>
                            <br />
                            <div className='.fadeup'>

                                <ColumnAux className='' isMiddle={false} size={11}>
                                    <Box className='pad0 fade3  marginTop10 is-radiusless' >
                                        <Columns className='is-mobile'>
                                            <Columns.Column size={1} />
                                            <Columns.Column size={5}>
                                                <div className='is-size-7 has-text-weight-light  verticalLeftCenter'> We will analyse your details.</div>
                                            </Columns.Column>
                                            <Columns.Column>
                                                <Image className='is-clipped ' src={imageNamed('success-process-1-bw')} size='3by2' />
                                            </Columns.Column>
                                            <Columns.Column size={1} />

                                        </Columns>
                                    </Box>
                                </ColumnAux>
                                <ColumnAux isMiddle={false} size={11}>
                                    <Box className='pad0 fade6 is-radiusless' >
                                        <Columns className='is-mobile'>
                                            <Columns.Column size={1} />

                                            <Columns.Column size={5}>
                                                <div className='is-size-7 has-text-weight-light  verticalLeftCenter'> Handpick & Handcraft.</div>
                                            </Columns.Column>
                                            <Columns.Column>
                                                <Image className='is-clipped ' src={imageNamed('success-process-2-bw')} size='3by2' />
                                            </Columns.Column>
                                            <Columns.Column size={1} />

                                        </Columns>
                                    </Box>
                                </ColumnAux>

                                <ColumnAux isMiddle={false} size={11}>
                                    <Box className='pad0 fade9 is-radiusless' >
                                        <Columns className='is-mobile'>
                                            <Columns.Column size={1} />

                                            <Columns.Column size={5}>
                                                <div className='is-size-7 has-text-weight-light  verticalLeftCenter'> Get Your Apparel Ready.</div>
                                            </Columns.Column>
                                            <Columns.Column>
                                                <Image className='is-clipped ' src={imageNamed('success-process-3-bw')} size='3by2' />
                                            </Columns.Column>
                                            <Columns.Column size={1} />

                                        </Columns>
                                    </Box>
                                </ColumnAux>

                                <p className='is-expanded marginTop30'>
                                    <Button className='  is-radiusless type74 is-black' onClick={togglePaymentSuccess}  > VIEW ORDER
                                        <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i>
                                    </Button>
                                    {/* <Button className=' marginLeft20  is-radiusless type60 is-text no-decoration' onClick={() => { this.toggleSuccessOrderModal() }} > CONTINUE ORDERING </Button> */}
                                </p>
                                <br />
                                <br />
                            </div>


                        </ColumnAux>
                    </Box>
                </Modal.Content>
            </Modal>
        )
    }



    return (
        orderModal(order)
    )
}



export default OrderModal
