
import { PRODUCT_TYPE } from '../utilities/utils'

//____CHANGE PROCESS______

//1. CHANGE LOGO 
// EVERYWHERE THERE IS assets/logoTailorwise.svg imported. Change to clientLogo or clientLogoNav or clientLogoMenu. Upload these first.

//2. CHANGE BACKEND APPID, JSKEY & RESTKEY 
// Change APPID, JSKEY & RESTKEY in .env.development & .env.production

//3. CHANGE STRIPE ACCOUNT ID 
// Change REACT_APP_STRIPE_PUBLISHABLE_KEY in .env.development & .env.production

//4. IF PAYPAL . CHANGE IMPORT LINE in /public/index.html 
// Change   <script src="https://www.paypal.com/sdk/js?client-id=<CLIENT-KEY>&components=buttons&currency=<CURRENCY-3LETTER-CODE DEFAULT USD>"></script>


//5. CHANGE PAYPAL LINK in header SRC>MANIFEST 

//6. CHANGE MANIFEST in SRC>MANIFEST 

//7. CHANGE CONFIG HERE & in WEBSHOP
// Change isOpenWithoutSignIn and hasShop Here.

//8. VALIDATE DOMAIN FOR EMAILS
// Validate client domain on Sendgrid to make sure emails work as expected.

//9. Upload & copy Assets on AWS
// Make folder for client, copy measurement images & videos from existing folders into it. And upload clients logo. Input urls underneath.

//10. Change All the info undersneath

export const clientName = "Sleek Studio"


export const hasShop = false
export const skipHomePage = false
export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY


export const isCustomPostPaid_AllProducts = true
export const customPricePolicyLine = "Price of order is updated after evaluation by " + clientName

// If yes put PaypalCLientId & Currency Code in public>Index.html line-20~25
export const hasPaypal = false
export const paypalClientId = ""


export const hasHomepageBrief = true
export const hasHomepageInsignia = true
export const hasHomePageContacts = true
export const homepageInsigniaUrl = ""
export const homepageInsigniaSize = 0


export const clientPhone = "9317401740" // missing
export const clientEmail = "taranjotsingh@thesleekstudio.com"
// export const clientEmail = "info@thesleekstudio.com"
// export const clientEmailFromForOrder = "no-reply@tailorwise.com"

// export const clientEmail = "appsquare.in@gmail.com"
export const clientEmailFromForOrder = "no-reply@tailorwise.com"

export const homepageContacts_Max3 = [
    {
        type: "email",
        contact: clientEmail
    },
    {
        type: "phone",
        contact: "9317401740"
    },
    {
        type: "phone",
        contact: "9815189818"
    }
]
export const homepageBrief = `“Sleek Studio is a made-for-you celebration wear brand that takes care of you, cherishes, and celebrates your life."
Our passion for craft, precision, and efficiency sets us apart from the competition. Sleek studio believes that there are multiple occasions in a person's life that they wait a lifetime for, and we want to make those special moments even more memorable.`



const homepage = "https://sleekstudio.tailorwise.com" //Change in public> manifest.json also


export const standardDeliveryTime = "Standard Delivery Time of 4-5 weeks"
export const freeShippingThreshold = 0
export const standardPaidShippingPrice = 0


export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/SleekStudio/"
export const commonAwsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/common/"



// export const clientEmail = "appsquare.in@gmail.com"
// export const clientEmailFromForOrder = "appsquare.in@gmail.com"



export const clientWebsite = "https://thesleekstudio.com"
export const clientTermsAndConditionsUrl = "https://thesleekstudio.com/terms/"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/SleekStudio/sleekstudio-140x30.png"
export const clientInsignia = "https://tailorwise.s3.eu-west-2.amazonaws.com/clientassets/SleekStudio/sleek_insignia.png"
// export const currencySymbol = "₹"
// export const currencyCode = "INR"
export const currencySymbol = "$"
export const currencyCode = "USD"
export const clientLogoNav = ""
export const clientLogoHero = ""
export const clientBGHome = ""
export const clientBGHero = ""



export const availableProducts = [
    PRODUCT_TYPE.suit,
    PRODUCT_TYPE.jacket,
    PRODUCT_TYPE.overCoat,
    PRODUCT_TYPE.waistCoat,
    PRODUCT_TYPE.shirt,
    PRODUCT_TYPE.pant,
    PRODUCT_TYPE.tuxedo
]



// STRIPE
// const REACT_APP_STRIPE_PUBLISHABLE_KEY= "pk_test_PdGMAl4hboCdCwJXrOmT9Ky900p4ztI4OW" // USING APPSQUARE ACCOUNT - TESTMODE

const REACT_APP_STRIPE_PUBLISHABLE_KEY = "pk_test_51LGHsOSHFcOlFfRE3r0QpzrdGiFVQ2u8hdGizR871Agtja1SQg4PFu8vXWOyeiTBA8x2A1CY33cDuCwxVzazuZCf00aricHL3G" // SLEEKSTUDIO - TESTMODE


// REAL TW-SLEEKSTUDIO Closet
const REACT_APP_BACKEND_APPID = "20uoQQ2SmikZNnYQMutBcUEh9O5tL1Drh7IYWgFi"
const REACT_APP_BACKEND_JSKEY = "nOOmQSXyMUpiZhC7KM78uXiVIraV8QDdH5CFIAZB"
const REACT_APP_BACKEND_RESTKEY = "RjqfcryYJyA2DJ7zPBJF8e8kXdNhd2LcETCZk3UN"






