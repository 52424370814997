import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import MeasureStep from './MeasureStep'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from './ColumnAux'
import LoadingAux from './LoadingAux'
import ChooseBox from './ChooseBox'

import { imageNamed, webImageNamed, scrollToRef, UNIT_TYPES, MEASURE_TYPE, UNIT_KIND, UnitGroupOptions, PRODUCT_TYPE, uniqId, EXTRA_STEP_TYPE, appliesOnProducts, createCMOptions } from '../utilities/utils'
import { getCurrentCustomer, saveCurrentCustomer } from '../utilities/Login'
 





// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class MeasurementsWizard extends Component {



    customerMeasureForKey = (key) => {

        let cust = getCurrentCustomer()

        if (cust === null){
            return null
        }

        let existingMeasure = cust.conciergeMeasurements ?? null

        if (existingMeasure === null){
            return null
        } 

        if (existingMeasure.hasOwnProperty(key)){
            let valObj = existingMeasure[key]
            return valObj.value
        }

        return null
    }


    

    allMeasures = [
        {
            id: 'ehgejh86376387',
            isMessageScreen: true,
            title: "Let's Measure your Canvas.",
            subtitle: 'Please read and prepare with these points before we begin.',
            points: [
                'You need a measuring tape.',
                'You can do it yourself, but having assistance makes it comfortable.',
                'Measure yourself correctly. Fitness preference are selected for each product/style seperately.'
            ],
            name: '',
            imageSrc: imageNamed('IconBg-Measure'),
            imageSize: '16by9',
            videoSrc: '',
            showUnitChoice: false,
            options: [41],
            isFirst: false,
            isLast: false,
            skipable: false,
            overrideNext: 'OK ',
            type: MEASURE_TYPE.null,
            applicable: [EXTRA_STEP_TYPE.welcome]
        },
        {
            id: 'es27627637887',
            isMessageScreen: true,
            title: 'Units',
            subtitle: 'The values will be entered in these units. You can change them right now if you want. ',
            points: [
            ],
            name: '',
            imageSrc: null,
            videoSrc: '',
            showUnitChoice: true,
            options: [41],
            isFirst: false,
            isLast: false,
            skipable: false,
            overrideNext: 'START',
            type: MEASURE_TYPE.null,
            applicable: [EXTRA_STEP_TYPE.units]
        },
        // {
        //     id: 'g763bj3kbj3bjk',
        //     name: 'Whats your height ?',
        //     remark: "",
        //     type: MEASURE_TYPE.height,
        //     unitKind: UNIT_KIND.lengthBig,
        //     imageSrc: imageNamed('IconBg-Height'),
        //     imageSize: '16by9',
        //     videoSrc: '',
        //     showOptions: true,
        //     options: [],  // inch
        //     ftOptions: ['5.0', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7', '5.8', '5.9', '5.10', '5.11', '6.0', '6.1', '6.2', '6.3'], // ft
        //     cmOptions: [150, 153, 155, 158, 160, 163, 165, 168, 170, 173, 175, 178, 180, 183, 185], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.height) ?? '5.11'),
        //     selectedWithUnit: UNIT_TYPES.ft,
        //     currentUnitType: UNIT_TYPES.ft,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: [EXTRA_STEP_TYPE.essential]
        // },
        // {
        //     id: 'dfbhfkkjjvfkfjvkfsj',
        //     name: 'Whats your weight ?',
        //     remark: "",
        //     type: MEASURE_TYPE.weight,
        //     unitKind: UNIT_KIND.weight,
        //     imageSrc: imageNamed('IconBg-Weight2'),
        //     imageSize: '16by9',
        //     videoSrc: '',
        //     showOptions: false,
        //     options: [],  // inch
        //     ftOptions: [], // ft
        //     cmOptions: [], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.weight) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.kg,
        //     currentUnitType: UNIT_TYPES.kg,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: true,
        //     applicable: [EXTRA_STEP_TYPE.essential]
        // },
            // {
            //     id: 'bdffkfjfbkjbskjbkfbd',
            //     isMessageScreen: false,
            //     name: 'Your Age ?',
            //     type: MEASURE_TYPE.age,
            //     unitKind: UNIT_KIND.age,
            //     imageSrc: imageNamed('IconBg-Age2'),
            //     videoSrc: '',
            //     showOptions: false,
            //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.age) ?? ''),
            //     selectedWithUnit: UNIT_TYPES.year,
            //     currentUnitType: UNIT_TYPES.year,
            //     isFirst: false,
            //     isLast: false,
            //     skipable: false,
            //     applicable: [EXTRA_STEP_TYPE.essential]
            // },
        { 
            id: 'fbdjkbfjbdjbkfjdbbjbf',
            name: 'Neck Size ?',
            remark: "",
            type: MEASURE_TYPE.NECK,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/NECK.png', false),
            imageSize: '16by9',
            videoSrc: webImageNamed('MeasurementVideos/Neck.mp4', false),
            showOptions: true,
            options: ["12","12.5","13","13.5","14","14.5","15","15.5","16"],  // inch
            cmOptions: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.NECK) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.NECK)
        },    
        {  
            id: 'dfbfkjbsjbjkjbf',
            name: 'SHOULDER',
            remark: "",
            type: MEASURE_TYPE.SHOULDER,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/SHOULDER-FRONT-AND-BACK.png', false),
            videoSrc: webImageNamed('MeasurementVideos/FrontShoulder.mp4', false),
            showOptions: true,
            options: [ "14","14.5","15","15.5","16","16.5","17","17.5","18","18.5","19"],  // inch
            cmOptions: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.SHOULDER_BACK) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.SHOULDER)
        },
        { 
            id: 'vdvbdjbkjbvkdj',
            name: 'BACK LENGTH (Nape to Waist)',
            type: MEASURE_TYPE.NAPE_TO_WAIST,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/NAPE-TO-WAIST.png', true),
            videoSrc: webImageNamed('MeasurementVideos/BackLength.mp4', true),
            showOptions: true,
            options: [ '22','23','24', '25', '26', '27','28','29', '30', '31','32', '33', '34','35','36'],  // inch
            cmOptions: [50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.NAPE_TO_WAIST) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.NAPE_TO_WAIST)
        },
        { 
            id: 'fdjfkbfjbskfbjkbf',
            // name: 'SLEEVE (RIGHT & LEFT)',
            // remark: "Reduce 1.5 inch - after calculating",

            name: 'ARMS LENGTH',
            remark: "",
            type: MEASURE_TYPE.SLEEVE,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/SLEEVE.png', false),
            videoSrc: webImageNamed('MeasurementVideos/SleeveLength.mp4', false),
            showOptions: true,
            options: [ "20","21","22","23","24","25","26","27","28","29","30"],  // inch
            cmOptions: [50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.SLEEVE) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.SLEEVE)
        },
        // {  
        //     id: 'dfbfkjbsjbjkjbf',
        //     name: 'SHOULDER FRONT',
        //     remark: "",
        //     type: MEASURE_TYPE.SHOULDER_FRONT,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/SHOULDER-FRONT-AND-BACK.png', true),
        //     videoSrc: webImageNamed('MeasurementVideos/FrontShoulder.mp4', true),
        //     showOptions: true,
        //     options: [ '14', '15', '16','17', '18', '19', '20','21', '22', '23','24','25'],  // inch
        //     cmOptions: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.SHOULDER_FRONT) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.SHOULDER_FRONT)
        // },
        { 
            id: 'djbfkjjfbfkbfkjb',
            name: 'Chest Width ?',
            remark: "",
            type: MEASURE_TYPE.CHEST,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/CHEST-WIDTH.png', false),
            videoSrc: webImageNamed('MeasurementVideos/Chest.mp4', false),
            showOptions: true,
            options: [ "36","37","38","39","40","41","42","43","44","45","46","47","48"],  // inch
            cmOptions: [90, 92, 94, 96, 98, 100, 102, 104, 106, 108, 110, 112, 114, 116, 118, 120], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.CHEST) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: true,
            applicable: appliesOnProducts(MEASURE_TYPE.CHEST)
        },
        { 
            id: 'fdbfjkfbsbjfbjbkj',
            // name: 'STOMACH',
            name: 'BELLY',
            remark: "",
            type: MEASURE_TYPE.STOMACH,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/STOMACH.png', false),
            videoSrc: webImageNamed('MeasurementVideos/Stomach.mp4', false),
            showOptions: true,
            options: [ "36","37","38","39","40","41","42","43","44","45","46","47","48"],  // inch
            cmOptions: [90, 92, 94, 96, 98, 100, 102, 104, 106, 108, 110, 112, 114, 116, 118, 120], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.STOMACH) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.STOMACH)
        },
        { 
            id: 'dfjbfjsbkdbjkfjf',
            name: 'Waiste Size ?',
            remark: "",
            type: MEASURE_TYPE.WAIST,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/PANT-WAIST.png', false),
            videoSrc: webImageNamed('MeasurementVideos/PantWaist.mp4', false),
            showOptions: true,
            options: [ "30","31","32","33","34","35","36","37","38"],  // inch
            cmOptions: [75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.WAIST) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.WAIST)
        },
             // { 
        //     id: 'dbffbkjkdbjdbfjb',
        //     name: 'FRONT WAIST HEIGHT',
        //     type: MEASURE_TYPE.FRONT_WAIST_HEIGHT,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/FRONT-WAIST-HEIGHT.png', true),
        //     videoSrc: webImageNamed('MeasurementVideos/FrontWaistHeight.mp4', true),
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.FRONT_WAIST_HEIGHT) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.FRONT_WAIST_HEIGHT)
        // },
        // { 
        //     id: 'djbfkjbdjkfbfdkbjf',
        //     name: 'BACK WAIST HEIGHT',
        //     type: MEASURE_TYPE.BACK_WAIST_HEIGHT,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/BACK-WAIST-HEIGHT.png', true),
        //     videoSrc: webImageNamed('MeasurementVideos/BackWaistHeight.mp4', true),
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.BACK_WAIST_HEIGHT) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.BACK_WAIST_HEIGHT)
        // },
        // { 
        //     id: 'njfnkjdfbjkbds',
        //     name: 'FRONT WAIST LENGTH',
        //     type: MEASURE_TYPE.FRONT_WAIST_LENGTH,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/FRONT-WAIST-LENGTH.png', true),
        //     videoSrc: webImageNamed('MeasurementVideos/FrontWaistLength.mp4', true),
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.FRONT_WAIST_LENGTH) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.FRONT_WAIST_LENGTH)
        // },


        { 
            id: 'dknflknfkfnksfndkfd',
            // name: 'SEAT',
            name: 'HIPS',
            remark: "",
            type: MEASURE_TYPE.SEAT,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/SEAT.png', false),
            videoSrc: webImageNamed('MeasurementVideos/Hips.mp4', false),
            showOptions: true,
            options: [ "36","37","38","39","40","41","42","43","44","45","46"],  // inch
            cmOptions: [90, 92, 94, 96, 98, 100, 102, 104, 106, 108, 110, 112, 114, 116, 118, 120], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.SEAT) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.SEAT)
        },

        // { 
        //     id: 'vnlkdnldkvnsknvl',
        //     name: 'WAISTCOAT LENGTH',
        //     remark: "",
        //     type: MEASURE_TYPE.WAISTCOAT_LENGTH,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/WAIST-COAT-LENGTH.png', true),
        //     videoSrc: null,
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.WAISTCOAT_LENGTH) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.WAISTCOAT_LENGTH)
        // },
        // { 
        //     id: 'nvklsnkdnlvnk',
        //     name: 'OVERCOAT LENGTH',
        //     remark: "",
        //     type: MEASURE_TYPE.OVERCOAT_LENGTH,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/OVER-COAT-LENGTH.png', true),
        //     videoSrc: null,
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.OVERCOAT_LENGTH) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.OVERCOAT_LENGTH)
        // },
        { 
            id: 'jfbkdbjkbkdbfk',
            // name: 'JACKET LENGTH',
            name: 'SUIT LENGTH',
            remark: "",
            type: MEASURE_TYPE.JACKET_LENGTH,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/JacketLength.png', false),
            videoSrc: null,
            showOptions: true,
            options: [ "30","31","32","33","34","35","36","37","38"],  // inch
            cmOptions: [75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.JACKET_LENGTH) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.JACKET_LENGTH)
        },
        { 
            id: '26876873vsgshj2675762',
            // name: 'JACKET LENGTH',
            name: 'KURTA LENGTH',
            remark: "",
            type: MEASURE_TYPE.KURTA_LENGTH,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/KurtaLength.png', false),
            videoSrc: null,
            showOptions: true,
            options: [ "35","36","37","38","39","40","41","42","43","44","45"],  // inch
            cmOptions: [85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.KURTA_LENGTH) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.KURTA_LENGTH)
        },
        // { 
        //     id: 'bfjkdsbjdbkjbdf',
        //     name: 'SHIRT LENGTH',
        //     remark: "",
        //     type: MEASURE_TYPE.SHIRT_LENGTH,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/SHIRT-LENGTH.png', true),
        //     videoSrc: null,
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.SHIRT_LENGTH) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.SHIRT_LENGTH)
        // },
        // { 
        //     id: 'jfkbjsbfjbfksjdbfjk',
        //     name: 'BICEP',
        //     remark: "",
        //     type: MEASURE_TYPE.BICEP,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/BICEP.png', true),
        //     videoSrc: webImageNamed('MeasurementVideos/Bicep.mp4', true),
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.BICEP) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.BICEP)
        // },
        // { 
        //     id: 'dfjbjdbfkjdbsdj',
        //     name: 'CUFF',
        //     remark: "",
        //     type: MEASURE_TYPE.CUFF,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/CUFF.png', true),
        //     videoSrc: webImageNamed('MeasurementVideos/Wrist.mp4', true),
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.CUFF) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.CUFF)
        // },
        { 
            id: 'djksbdjbsjabjsjdb',
            // name: 'OUTSEAM',
            // remark: "Reduce 1 inch - after calculating",
            name: 'PANT LENGTH',
            remark: "",
            type: MEASURE_TYPE.OUTSEAM,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/PANT-LENGTH.png', false),
            videoSrc: webImageNamed('MeasurementVideos/PantLength.mp4', false),
            showOptions: true,
            options: [ "35","36","37","38","39","40","41","42","43","44","45"],  // inch
            cmOptions: [85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.OUTSEAM) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.OUTSEAM)
        },
        {  
            id: 'dbkjbdjbbjddde',
            // name: 'URISE',
            name: 'CROTCH',
            remark: "",
            type: MEASURE_TYPE.URISE,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/URISE.png', false),
            videoSrc: webImageNamed('MeasurementVideos/URise.mp4', false),
            showOptions: true,
            options: [ "20","21","22","23","24","25","26","27","28","29","30"],  // inch
            cmOptions: [50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.URISE) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.URISE)
        },
        { 
            id: 'jdbskjdbsjbkajba',
            name: 'THIGHS',
            remark: "",
            type: MEASURE_TYPE.THIGH,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/THIGH.png', false),
            videoSrc: webImageNamed('MeasurementVideos/Thigh.mp4', false),
            showOptions: true,
            options: [ "16","17","18","19","20","21","22","23","24"],  // inch
            cmOptions: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.THIGH) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.THIGH)
        },
        // { 
        //     id: 'ajbdkjsbjdbbbjd',
        //     name: 'KNEE',
        //     remark: "",
        //     type: MEASURE_TYPE.KNEE,
        //     unitKind: UNIT_KIND.length,
        //     imageSrc: webImageNamed('MeasurementImages/KNEE-WIDTH.png', true),
        //     videoSrc: null,
        //     showOptions: true,
        //     options: [ '38','39','40', '41', '42', '43','44','45', '46', '47','48', '49', '50','51','52'],  // inch
        //     cmOptions: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60], // cm
        //     selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.KNEE) ?? ''),
        //     selectedWithUnit: UNIT_TYPES.inchs,
        //     currentUnitType: UNIT_TYPES.inchs,
        //     isFirst: false,
        //     isLast: false,
        //     skipable: false,
        //     applicable: appliesOnProducts(MEASURE_TYPE.KNEE)
        // },
        {
            id: 'jdbkjbjbdkjbkjb',
            name: 'BOTTOM CUFF',
            remark: "",
            type: MEASURE_TYPE.ANKLE,
            unitKind: UNIT_KIND.length,
            imageSrc: webImageNamed('MeasurementImages/AnkleWidth.png', false),
            videoSrc: null,
            showOptions: true,
            options: [ "9","9.5","10","10.5","11","11.5","12","12.5","13","13.5","14"],  // inch
            cmOptions: [20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35], // cm
            selectedValue: '' + (this.customerMeasureForKey(MEASURE_TYPE.ANKLE) ?? ''),
            selectedWithUnit: UNIT_TYPES.inchs,
            currentUnitType: UNIT_TYPES.inchs,
            isFirst: false,
            isLast: false,
            skipable: false,
            applicable: appliesOnProducts(MEASURE_TYPE.ANKLE)
        },


    ]


    _editingContainsCMLB = false



    findTypeAndUpdateVal = (type, val, unit) => {
        // let measuresWithThisType = this.measures.filter( ms => {
        //     return ms.type === type
        // })
        let measureIndex = this.allMeasures.findIndex(ms => ms.type === type)
        console.log('Index Of Measure with Type = ' + type + ' is ' + measureIndex)
        let newMeasure = this.allMeasures[measureIndex]
        console.log('NEW MEASURE  = ', newMeasure)
        newMeasure.selectedValue = val
        newMeasure.selectedWithUnit = unit
        if (unit === UNIT_TYPES.cm || unit === UNIT_TYPES.lb) {
            this._editingContainsCMLB = true
        }
        this.allMeasures[measureIndex] = newMeasure
    }



    updateMeasuresForEditing = (measures) => {
        // console.log('--------------------UPDATING MEASUREMNST WITH -', measures)
        const result = Object.keys(measures).map(function (key) {
            return [key, measures[key]];
        });

        const withoutNull = result.filter(ar => {
            return !ar.includes(null)
        })

        // Remove Welcome and Unit Screens
        // this.allMeasures = this.allMeasures.splice(0,2)
        // this.allMeasures.shift()
        // this.allMeasures.shift()

        withoutNull.forEach(ms => {
            let type = ms[0]
            let val = ms[1].value
            let unit = ms[1].unit
            this.findTypeAndUpdateVal(type, val, unit)
        })

        if (this._editingContainsCMLB) {
            setTimeout(() => {
                this.unitChanged(UnitGroupOptions.CmLb, false)
            }, 400);
        }
        // console.log('ALL MEASURES NOW -', this.allMeasures)
        // console.log('============UPDATING COMPLETE')

    }

    markFirstAndLast = (arr) => {
        let copy = [...arr]
        copy[0].isFirst = true
        copy[arr.length - 1].isLast = true
        return copy
    }

    initMeasures = () => {

        let welcomeScreen = this.allMeasures[0]
        let unitScreen = this.allMeasures[1]

        if (this.props.welcomeTitle) {
            welcomeScreen.title = this.props.welcomeTitle
        }

        if (this.props.editingMeasure) {
            let editingMeasure = { ...this.props.editingMeasure }
            this.updateMeasuresForEditing(editingMeasure.measures)
        }


        let newArr = []
        console.log('CHECKING PRODUCTS ARRAY')

        console.log('INCOMING PRODUCTS ARRAY =', this.props.productsArray)

        // if (this.props.productsArray && this.props.productsArray.length > 0) {
        //     console.log('PRODUCTS ARRAY EXITS')

        //     const essentialOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(EXTRA_STEP_TYPE.essential) })
        //     // const suitOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(PRODUCT_TYPE.suit) })
        //     const waistCoatOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(PRODUCT_TYPE.waistCoat) })
        //     const jacketOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(PRODUCT_TYPE.jacket) })
        //     const shirtOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(PRODUCT_TYPE.shirt) })
        //     const pantOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(PRODUCT_TYPE.pant) })
        //     const overCoatOnly = this.allMeasures.filter(ms => { return ms.applicable.includes(PRODUCT_TYPE.overCoat) })

        //     newArr = newArr.concat(essentialOnly)

        //     let products = this.props.productsArray

        //     // if (products.includes(PRODUCT_TYPE.suit)) {
        //     //     console.log('CONCATING SUITS ONLY')
        //     //     console.log(' SUITS ONLY ARRAY =', suitOnly)

        //     //     newArr = newArr.concat(suitOnly)
        //     //     console.log(' AFTER CONCAT ARRAY =', newArr)
        //     // }

        //     if (products.includes(PRODUCT_TYPE.waistCoat)) {
        //         newArr = newArr.concat(waistCoatOnly)
        //     }
        //     if (products.includes(PRODUCT_TYPE.jacket)) {
        //         newArr = newArr.concat(jacketOnly)
        //     }
        //     if (products.includes(PRODUCT_TYPE.shirt)) {
        //         newArr = newArr.concat(shirtOnly)
        //     }
        //     if (products.includes(PRODUCT_TYPE.pant)) {
        //         newArr = newArr.concat(pantOnly)
        //     }
        //     if (products.includes(PRODUCT_TYPE.overCoat)) {
        //         newArr = newArr.concat(overCoatOnly)
        //     }

        //     newArr = Array.from(new Set(newArr))  // only unique elements
            
            
        //     if (!this.props.editingMeasure) {
        //         console.log('__________*******IS NOT EDITING')

        //         newArr.unshift(welcomeScreen, unitScreen)
        //     }else{
        //         console.log('__________*******IS  EDITING')

        //         newArr.unshift(unitScreen)
        //     }

        // } else {
        //     console.log('PRODUCTS ARRAY DOES NOT EXITS')

            newArr = this.allMeasures
        // }




        // filter as per incoming props
        console.log('============THIS MEASURE IS SET')
        return this.markFirstAndLast(newArr)
    }




    state = {
        unitGroupOption             : UnitGroupOptions.FtInchKg,
        productsArray               : null,
        selected_Unit_Length_Big    : UNIT_TYPES.ft,
        selected_Unit_Length        : UNIT_TYPES.inchs,
        selected_Unit_Weight        : UNIT_TYPES.kg,
        selected_Age                : null,
        selected_Height             : null,
        selected_Weight             : null,
        
        selected_NECK               : null, 
        selected_SHOULDER           : null,
        selected_SLEEVE             : null,
        selected_SEAT               : null,
        selected_CHEST              : null,
        selected_STOMACH            : null,
        selected_BICEP              : null,
        selected_CUFF               : null,
        selected_WAIST              : null,
        selected_OUTSEAM            : null,
        selected_THIGH              : null,
        selected_KNEE               : null,
        selected_ANKLE              : null,
        selected_URISE              : null,
        selected_FRONT_WAIST_HEIGHT : null,
        selected_BACK_WAIST_HEIGHT  : null,
        selected_FRONT_WAIST_LENGTH : null,         
        selected_NAPE_TO_WAIST      : null,          
        selected_WAISTCOAT_LENGTH   : null,        
        selected_OVERCOAT_LENGTH    : null,         
        selected_JACKET_LENGTH      : null,         
        selected_KURTA_LENGTH      : null,         
        selected_SHIRT_LENGTH       : null,                   

        isComplete: false,
        isCancelled: false,
        selectedStart: null,
        editingMeasure: null,
        measures: this.initMeasures()
    }


    _mounted = false

    // Update Measures 




    showCustomCMOptions = () => {

        console.log("\n\n\n ========= ALL CM OPTIONS HERE =======  \n\n\n");

        createCMOptions(11, 19, "neck")
        createCMOptions(14, 25, "shoulder")
        createCMOptions(32, 46, "chest")
        createCMOptions(22, 36, "sleeve") 
        createCMOptions(26, 40, "waiste" )
        createCMOptions(30, 44, "seat")
        createCMOptions(38, 52, "stomach")
        createCMOptions(32, 52, "frontWaistHeight")
        createCMOptions(32, 52, "backWaistHeight" )
        createCMOptions(32, 52, "frontWaistLength")
        createCMOptions(32, 52, "napeToWist" )
        createCMOptions(32, 52, "waistCoatLength")
        createCMOptions(32, 52, "overCoatLength" )
        createCMOptions(32, 52, "jacketLength")
        createCMOptions(32, 52, "shirtLength" )
        createCMOptions(8, 28, "bicep")
        createCMOptions(8, 18, "cuff" )
        createCMOptions(15, 35, "outseam")
        createCMOptions(9, 29, "thigh")
        createCMOptions(7, 24, "knee" )
        createCMOptions(6, 16, "ankle" )
        createCMOptions(10, 30, "urise" )

        console.log("\n\n\n ========= ALL CM OPTIONS END =======  \n\n\n");

         
        
    }




    componentDidMount = () => {
        this._mounted = true

        this.showCustomCMOptions()

        // let updateMeasures = false
        let ns = { ...this.state }
        // if (this.props.editingMeasure){
        //     ns.editingMeasure = this.props.editingMeasure
        // }
        if (this.props.productsArray) {
            ns.productsArray = this.props.productsArray
        }

        if (this.props.editingMeasure) {
            let editingMeasure = { ...this.props.editingMeasure }
            ns.editingMeasure = editingMeasure
            // updateMeasures = true
        }

        this.setState(ns)

        // if (updateMeasures){
        //     this.updateMeasuresForEditing(this.props.editingMeasure.measures)
        // }

    }

    componentWillUnmount = () => {
        this._mounted = false
    }


    log = (something) => {
        console.log(something)
    }

    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    };

    onNavChangeHandler = (direction, type, val) => {
        console.log('CHANGE HAPPEND -', direction)

        this.scrollToTop()

        if (direction === 'cancelled') {
            // Cancel Pressed
        }

        if (this._mounted && (direction === 'next' || direction === 'done')) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }

            console.log('CHANGING VALUES with type =' + type + ' . And Value =', val)


            switch (type) {
                case MEASURE_TYPE.age: ns.selected_Age = { value: val, unit: 'Years' }; break;
                case MEASURE_TYPE.height: ns.selected_Height = { value: val, unit: this.state.selected_Unit_Length_Big }; this.log('Updating height'); break;
                case MEASURE_TYPE.weight: ns.selected_Weight = { value: val, unit: this.state.selected_Unit_Weight }; this.log('Updating weight'); break;

                case MEASURE_TYPE.NECK: ns.selected_NECK = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating NECK'); break;
                case MEASURE_TYPE.SHOULDER: ns.selected_SHOULDER = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating SHOULDER'); break;
                
                case MEASURE_TYPE.SLEEVE: ns.selected_SLEEVE = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating SLEEVE'); break;
                case MEASURE_TYPE.STOMACH: ns.selected_STOMACH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating STOMACH'); break;

                case MEASURE_TYPE.SEAT: ns.selected_SEAT = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating SEAT'); break;
                case MEASURE_TYPE.CHEST: ns.selected_CHEST = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating CHEST'); break;

                case MEASURE_TYPE.BICEP: ns.selected_BICEP = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating BICEP'); break;
                case MEASURE_TYPE.CUFF: ns.selected_CUFF = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating CUFF'); break;
                case MEASURE_TYPE.WAIST: ns.selected_WAIST = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating WAIST'); break;

                case MEASURE_TYPE.OUTSEAM: ns.selected_OUTSEAM = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating OUTSEAM'); break;
                case MEASURE_TYPE.THIGH: ns.selected_THIGH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating THIGH'); break;
                case MEASURE_TYPE.KNEE: ns.selected_KNEE = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating KNEE'); break;

                case MEASURE_TYPE.ANKLE: ns.selected_ANKLE = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating ANKLE'); break;
                case MEASURE_TYPE.URISE: ns.selected_URISE = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating URISE'); break;
               
                case MEASURE_TYPE.FRONT_WAIST_HEIGHT: ns.selected_FRONT_WAIST_HEIGHT = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating FRONT_WAIST_HEIGHT'); break;
                case MEASURE_TYPE.BACK_WAIST_HEIGHT: ns.selected_BACK_WAIST_HEIGHT = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating BACK_WAIST_HEIGHT'); break;

                case MEASURE_TYPE.FRONT_WAIST_LENGTH: ns.selected_FRONT_WAIST_LENGTH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating FRONT_WAIST_LENGTH'); break;
                case MEASURE_TYPE.NAPE_TO_WAIST: ns.selected_NAPE_TO_WAIST = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating NAPE_TO_WAIST'); break;

                case MEASURE_TYPE.JACKET_LENGTH: ns.selected_JACKET_LENGTH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating JACKET_LENGTH'); break;
                case MEASURE_TYPE.KURTA_LENGTH: ns.selected_KURTA_LENGTH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating KURTA LENGTH'); break;

                case MEASURE_TYPE.WAISTCOAT_LENGTH: ns.selected_WAISTCOAT_LENGTH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating WAISTCOAT_LENGTH'); break;
                case MEASURE_TYPE.OVERCOAT_LENGTH: ns.selected_OVERCOAT_LENGTH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating OVERCOAT_LENGTH'); break;
                case MEASURE_TYPE.SHIRT_LENGTH: ns.selected_SHIRT_LENGTH = { value: val, unit: this.state.selected_Unit_Length }; this.log('Updating SHIRT_LENGTH'); break;

                default: break;
            }


            this.setState(ns)

            if (direction === 'done') {
                setTimeout(() => {
                    this.donePressed()
                }, 1000);
            }

        }
    }

    donePressed = () => {
        // Create And Save Measurement Here first

        if (this._mounted) {
            // let ns = { ...this.state }
            // ns.isComplete = true
            // this.setState(ns)

            let uniqId = 'id' + (new Date()).getTime();

            if (this.state.editingMeasure) {
                uniqId = this.state.editingMeasure.key
            }

            console.log('CREATING MEASURE OBJ')

            let mrs = {}


            if (this.state.selected_Age) mrs[MEASURE_TYPE.age] = this.state.selected_Age;
            if (this.state.selected_Height) mrs[MEASURE_TYPE.height] = this.state.selected_Height;
            if (this.state.selected_Weight) mrs[MEASURE_TYPE.weight] = this.state.selected_Weight;
            if (this.state.selected_NECK               )  mrs[MEASURE_TYPE.NECK              ] = this.state.selected_NECK                
            if (this.state.selected_SHOULDER           )  mrs[MEASURE_TYPE.SHOULDER          ] = this.state.selected_SHOULDER           
            if (this.state.selected_SLEEVE             )  mrs[MEASURE_TYPE.SLEEVE            ] = this.state.selected_SLEEVE             
            if (this.state.selected_SEAT               )  mrs[MEASURE_TYPE.SEAT              ] = this.state.selected_SEAT               
            if (this.state.selected_CHEST              )  mrs[MEASURE_TYPE.CHEST             ] = this.state.selected_CHEST              
            if (this.state.selected_STOMACH            )  mrs[MEASURE_TYPE.STOMACH           ] = this.state.selected_STOMACH            
            if (this.state.selected_BICEP              )  mrs[MEASURE_TYPE.BICEP             ] = this.state.selected_BICEP              
            if (this.state.selected_CUFF               )  mrs[MEASURE_TYPE.CUFF              ] = this.state.selected_CUFF               
            if (this.state.selected_WAIST              )  mrs[MEASURE_TYPE.WAIST             ] = this.state.selected_WAIST              
            if (this.state.selected_OUTSEAM            )  mrs[MEASURE_TYPE.OUTSEAM           ] = this.state.selected_OUTSEAM            
            if (this.state.selected_THIGH              )  mrs[MEASURE_TYPE.THIGH             ] = this.state.selected_THIGH              
            if (this.state.selected_KNEE               )  mrs[MEASURE_TYPE.KNEE              ] = this.state.selected_KNEE               
            if (this.state.selected_ANKLE              )  mrs[MEASURE_TYPE.ANKLE             ] = this.state.selected_ANKLE              
            if (this.state.selected_URISE              )  mrs[MEASURE_TYPE.URISE             ] = this.state.selected_URISE              
            if (this.state.selected_FRONT_WAIST_HEIGHT )  mrs[MEASURE_TYPE.FRONT_WAIST_HEIGHT] = this.state.selected_FRONT_WAIST_HEIGHT 
            if (this.state.selected_BACK_WAIST_HEIGHT  )  mrs[MEASURE_TYPE.BACK_WAIST_HEIGHT ] = this.state.selected_BACK_WAIST_HEIGHT  
            if (this.state.selected_FRONT_WAIST_LENGTH )  mrs[MEASURE_TYPE.FRONT_WAIST_LENGTH] = this.state.selected_FRONT_WAIST_LENGTH          
            if (this.state.selected_NAPE_TO_WAIST      )  mrs[MEASURE_TYPE.NAPE_TO_WAIST     ] = this.state.selected_NAPE_TO_WAIST                
            if (this.state.selected_WAISTCOAT_LENGTH   )  mrs[MEASURE_TYPE.WAISTCOAT_LENGTH  ] = this.state.selected_WAISTCOAT_LENGTH           
            if (this.state.selected_OVERCOAT_LENGTH    )  mrs[MEASURE_TYPE.OVERCOAT_LENGTH   ] = this.state.selected_OVERCOAT_LENGTH             
            if (this.state.selected_JACKET_LENGTH      )  mrs[MEASURE_TYPE.JACKET_LENGTH     ] = this.state.selected_JACKET_LENGTH          
            if (this.state.selected_KURTA_LENGTH      )  mrs[MEASURE_TYPE.KURTA_LENGTH     ] = this.state.selected_KURTA_LENGTH               
     
            if (this.state.selected_SHIRT_LENGTH       )  mrs[MEASURE_TYPE.SHIRT_LENGTH      ] = this.state.selected_SHIRT_LENGTH        



            let measureObj = {
                key: uniqId,
                products: this.state.productsArray,
                description:  ((this.state.productsArray.length > 0) ?  'For ' + this.state.productsArray.join(',') : 'For All Products'),
                measures: mrs
            }

            console.log('Sending Measure Object -', measureObj)
            this.props.isDone('created', measureObj)

        }
    }

    letsStart = (code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedStart = code
            this.setState(ns)
        }
    }

    wizardChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 measurementsContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 measurementsContainer '>
            <Heading size={3} className='has-text-centered has-text-grey-light'> Lets start measuring you. </Heading>
            <br />
            <Button className='is-white is-centered' onClick={this.letsStart}> Lets Begin </Button>
        </Box>
            {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
        </div>
    }

    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }




    unitChanged = (choice, nullifyVal = true) => {
        console.log('UNIT CHANGED IN MAIN')

        if (this._mounted) {
            let ns = { ...this.state }
            // FtInchKg  or  CmLb
            if (choice === UnitGroupOptions.FtInchKg) {
                ns.selected_Unit_Length_Big = UNIT_TYPES.ft
                ns.selected_Unit_Length = UNIT_TYPES.inchs
                ns.selected_Unit_Weight = UNIT_TYPES.kg
                ns.unitGroupOption = UnitGroupOptions.FtInchKg

                // Change in this measures also
                let newMeasures = []
                ns.measures.forEach((ms) => {
                    let msCopy = { ...ms }
                    if (msCopy.unitKind === UNIT_KIND.lengthBig) {
                        msCopy.currentUnitType = UNIT_TYPES.ft
                    }
                    if (msCopy.unitKind === UNIT_KIND.length) {
                        msCopy.currentUnitType = UNIT_TYPES.inchs
                    }
                    if (msCopy.unitKind === UNIT_KIND.weight) {
                        msCopy.currentUnitType = UNIT_TYPES.kg
                    }
                    if (nullifyVal) {
                        msCopy.selectedValue = null
                    }
                    newMeasures.push(msCopy)
                })

                ns.measures = newMeasures

            } else {
                ns.selected_Unit_Length_Big = UNIT_TYPES.cm
                ns.selected_Unit_Length = UNIT_TYPES.cm
                ns.selected_Unit_Weight = UNIT_TYPES.lb
                ns.unitGroupOption = UnitGroupOptions.CmLb

                // Change in this measures also
                let newMeasures = []
                ns.measures.forEach((ms) => {
                    let msCopy = { ...ms }
                    if (msCopy.unitKind === UNIT_KIND.lengthBig) {
                        msCopy.currentUnitType = UNIT_TYPES.cm
                    }
                    if (msCopy.unitKind === UNIT_KIND.length) {
                        msCopy.currentUnitType = UNIT_TYPES.cm
                    }
                    if (msCopy.unitKind === UNIT_KIND.weight) {
                        msCopy.currentUnitType = UNIT_TYPES.lb
                    }
                    if (nullifyVal) {
                        msCopy.selectedValue = null
                    }
                    newMeasures.push(msCopy)
                })

                ns.measures = newMeasures

                // console.log('NEW    MEASURES = ', newMeasures)

            }

            this.setState(ns)
        }

    }

    measurementWizard = (showUploader = false) => {
        // wizardChooser
        let measureSteps = this.state.measures.map((measure) => {
            return <MeasureStep
                key={measure.id}
                id={measure.id}
                isMessageScreen={measure.isMessageScreen ?? false}
                title={measure.title}
                subtitle={measure.subtitle}
                showUnitChoice={measure.showUnitChoice}
                points={measure.points}
                name={measure.name}
                remark={measure.remark ?? ""}
                type={measure.type}
                imageSrc={measure.imageSrc}
                imageSize={measure.imageSize}
                videoSrc={measure.videoSrc}
                stepKey={measure.type}
                showOptions={measure.showOptions}
                options={measure.options}
                ftOptions={measure.ftOptions}
                cmOptions={measure.cmOptions}
                selectedValue={measure.selectedValue}
                selectedWithUnit={measure.selectedWithUnit}
                currentUnit={measure.currentUnitType}
                isFirst={measure.isFirst}
                isLast={measure.isLast}
                overrideNext={measure.overrideNext}
                skipable={measure.skipable}
                onNavChange={this.onNavChangeHandler}
                unitGroupOption={this.state.unitGroupOption}
                unitChanged={this.unitChanged}
            />
        })


        return (<div >
                        <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            <StepWizard>
                {measureSteps}
            </StepWizard>
            {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
        </div>
        )
    }


    currentScreen = () => {

        // if (this.state.editingStyle){
        //     return this.measurementWizard()
        // }

        // if (this.state.selectedStart) {
        //     if (this.state.selectedStart === this.STYLE_START.create) {
        return this.measurementWizard()
        // } else if (this.state.selectedStart === this.STYLE_START.upload) {
        //     return this.measurementWizard(true)
        // }
        // SELECTION CASE REMAINING
        // }
        // return this.wizardChooser()
    }

    render() {
        return (
            this.currentScreen()
        )
    }

}


export default MeasurementsWizard
