/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from '../containers/Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from '../containers/ColumnAux'
import LoadingAux from '../containers/LoadingAux'
import ChooseBox from '../containers/ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from './clientInfo'







// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForWaistCoat extends Component {

    basePrice = 0

    STEP_KEYS = {
        productType: '',
        frontButtons: 'FRONT BUTTONS',
        lapel: 'LAPEL',
        pockets: 'POCKETS',
        bottom: 'BOTTOM',
        backStrap: 'BACK STRAP',
        // monogram: 'Monogram',
        tone: 'TONE',
        fabric: 'FABRIC',
        fabricLining: 'FABRICC LINING', //NEW
        vestBack: 'VEST BACK',
        vestBackLining : 'VEST BACK LINING',
        buttons: 'BUTTONS', //NEW

    }

    TONE_KEYS = {
        ChooseForMe: 'SAME AS SUIT',
        AllSeasons: 'All Seasons',
        AllSeasonsPremium: 'All Seasons Premium',
        FabricMerinoWool: 'Fabric Merino Wool',
        // JacquardFabrics: 'Jacquard Fabrics',
        // WaterResistant: 'Water Resistant',
        // TuxedoContrast: 'Tuxedo Contrast'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }


    // selected_FrontButtons : frontButtons
    // selected_Lapel : lapel
    // selected_LapelWidth : lapelWidth
    // selected_Pockets : pockets
    // selected_Bottom : bottom
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric

    state = {
        uploadImage: null,

        selected_FrontButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.frontButtons : null,
        selected_Lapel: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lapel : null,
        // selected_LapelWidth: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lapelWidth : null,
        selected_Pockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pockets : null,
        selected_Bottom: this.props.editingProduct ? this.props.editingProduct.selectedSelections.bottom : null,
        selected_BackStrap: this.props.editingProduct ? this.props.editingProduct.selectedSelections.backStrap : null,
        // selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,

        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,
        selected_FabricLining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabricLining : null,

        selected_Buttons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.selected_Buttons : null,

        selected_VestBack: this.props.editingProduct ? this.props.editingProduct.selectedSelections.vestBack : null,
        selected_VestBackLining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.vestBackLining: null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF WAISTCOAT")
            // console.log(existingStyle)

            ns.selected_FrontButtons = existingStyle.selectedSelections.frontButtons
            ns.selected_Lapel = existingStyle.selectedSelections.lapel
            // ns.selected_LapelWidth = existingStyle.selectedSelections.lapelWidth
            ns.selected_Pockets = existingStyle.selectedSelections.pockets
            ns.selected_Bottom = existingStyle.selectedSelections.bottom
            ns.selected_BackStrap = existingStyle.selectedSelections.backStrap
            // ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric
            ns.selected_FabricLining = existingStyle.selectedSelections.fabricLining

            ns.selected_Buttons = existingStyle.selectedSelections.buttons

            ns.selected_VestBack = existingStyle.selectedSelections.vestBack
            ns.selected_VestBackLining = existingStyle.selectedSelections.vestBackLining


        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }



    // FRONT    https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/FrontButton/3BUTTON.jpg
    frontButtons_Opts = [
        { optType: "FRONT BUTTONS", styleSelectionId: "", key: 'THREE BUTTON', name: 'THREE BUTTON', imageSrc: webImageNamed('StyleSelections/WaistCoat/FrontButton/3BUTTON.jpg') },
        { optType: "FRONT BUTTONS", styleSelectionId: "", key: 'FOUR BUTTON', name: 'FOUR BUTTON', imageSrc: webImageNamed('StyleSelections/WaistCoat/FrontButton/4BUTTON.jpg') },
        { optType: "FRONT BUTTONS", styleSelectionId: "", key: 'FIVE BUTTON', name: 'FIVE BUTTON', imageSrc: webImageNamed('StyleSelections/WaistCoat/FrontButton/5BUTTON.jpg') },
        { optType: "FRONT BUTTONS", styleSelectionId: "", key: 'FOUR BUTTON 2 FASTEN', name: 'FOUR BUTTON 2 FASTEN', imageSrc: webImageNamed('StyleSelections/WaistCoat/FrontButton/4BUTTONwith2FASTEN.jpg') },
        { optType: "FRONT BUTTONS", styleSelectionId: "", key: 'SIX BUTTON 3 FASTEN', name: 'SIX BUTTON 3 FASTEN', imageSrc: webImageNamed('StyleSelections/WaistCoat/FrontButton/6BUTTONwith3FASTEN.jpg') },
    ]
    frontButtons_Step = () => {
        return {
            name: 'How would you like the front buttons?',
            key: this.STEP_KEYS.frontButtons,
            opts: this.frontButtons_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // LAPEL  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/Lapel/PEAK.jpg
    lapel_Opts = [
        { optType: "LAPEL", styleSelectionId: "", key: 'PEAK', name: 'PEAK', imageSrc: webImageNamed('StyleSelections/WaistCoat/Lapel/PEAK.jpg') },
        { optType: "LAPEL", styleSelectionId: "", key: 'NOTCH', name: 'NOTCH', imageSrc: webImageNamed('StyleSelections/WaistCoat/Lapel/NOTCH.jpg') },
        { optType: "LAPEL", styleSelectionId: "", key: 'SHAWL', name: 'SHAWL', imageSrc: webImageNamed('StyleSelections/WaistCoat/Lapel/SHAWL.jpg') },
        { optType: "LAPEL", styleSelectionId: "", key: 'V SHAPE', name: 'V SHAPE', imageSrc: webImageNamed('StyleSelections/WaistCoat/Lapel/Vshape.jpg') },
        { optType: "LAPEL", styleSelectionId: "", key: 'U SHAPE', name: 'U SHAPE', imageSrc: webImageNamed('StyleSelections/WaistCoat/Lapel/Ushape.jpg') },

    ]
    lapel_Step = () => {
        return {
            name: 'How would you like the Lapel Without Collar?',
            key: this.STEP_KEYS.lapel,
            opts: this.lapel_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // LOWER POCKETS  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/Pockets/NoPocket.jpg
    pockets_Opts = [
        { optType: "POCKETS", styleSelectionId: "", key: 'WELT', name: 'WELT', imageSrc: webImageNamed('StyleSelections/WaistCoat/Pockets/Welt.jpg') },
        { optType: "POCKETS", styleSelectionId: "", key: 'BESOM', name: 'BESOM', imageSrc: webImageNamed('StyleSelections/WaistCoat/Pockets/Besom.jpg') },
        { optType: "POCKETS", styleSelectionId: "", key: 'NONE', name: 'NONE', imageSrc: webImageNamed('StyleSelections/WaistCoat/Pockets/NoPocket.jpg') },
    ]
    pockets_Step = () => {
        return {
            name: 'And  Pockets',
            key: this.STEP_KEYS.pockets,
            opts: this.pockets_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // BOTTOM  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/Bottom/STRAIGHT.jpg
    bottom_Opts = [
        { optType: "BOTTOM", styleSelectionId: "", key: 'STRAIGHT', name: 'STRAIGHT', imageSrc: webImageNamed('StyleSelections/WaistCoat/Bottom/STRAIGHT.jpg') },
        { optType: "BOTTOM", styleSelectionId: "", key: 'POINTED', name: 'POINTED', imageSrc: webImageNamed('StyleSelections/WaistCoat/Bottom/POINTED.jpg') },
    ]
    bottom_Step = () => {
        return {
            name: 'BOTTOM STYLE',
            key: this.STEP_KEYS.bottom,
            opts: this.bottom_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // VENT     https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/BackStrap/Yes.jpg
    backStrap_Opts = [
        { optType: "BACK STRAP", styleSelectionId: "", key: 'YES', name: 'YES', imageSrc: webImageNamed('StyleSelections/WaistCoat/BackStrap/Yes.jpg') },
        { optType: "BACK STRAP", styleSelectionId: "", key: 'NO', name: 'NO', imageSrc: webImageNamed('StyleSelections/WaistCoat/BackStrap/No.jpg') },
    ]
    backStrap_Step = () => {
        return {
            name: 'Belt on Back',
            key: this.STEP_KEYS.backStrap,
            opts: this.backStrap_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // FABRIC LINING
    fabricLining_Opts = [
        // { optType: "Fabric Lining", key: 'A01', name: 'A01', imageSrc: webImageNamed('Fabric/SuitLining/A01.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '4', name: '4', imageSrc: webImageNamed('Fabric/Lining/4.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '5', name: '5', imageSrc: webImageNamed('Fabric/Lining/5.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '11', name: '11', imageSrc: webImageNamed('Fabric/Lining/11.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '12', name: '12', imageSrc: webImageNamed('Fabric/Lining/12.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '13', name: '13', imageSrc: webImageNamed('Fabric/Lining/13.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '19', name: '19', imageSrc: webImageNamed('Fabric/Lining/19.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '20', name: '20', imageSrc: webImageNamed('Fabric/Lining/20.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '21', name: '21', imageSrc: webImageNamed('Fabric/Lining/21.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '22', name: '22', imageSrc: webImageNamed('Fabric/Lining/22.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '23', name: '23', imageSrc: webImageNamed('Fabric/Lining/23.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '24', name: '24', imageSrc: webImageNamed('Fabric/Lining/24.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '25', name: '25', imageSrc: webImageNamed('Fabric/Lining/25.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '26', name: '26', imageSrc: webImageNamed('Fabric/Lining/26.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '29', name: '29', imageSrc: webImageNamed('Fabric/Lining/29.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '30', name: '30', imageSrc: webImageNamed('Fabric/Lining/30.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '31', name: '31', imageSrc: webImageNamed('Fabric/Lining/31.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '32', name: '32', imageSrc: webImageNamed('Fabric/Lining/32.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '33', name: '33', imageSrc: webImageNamed('Fabric/Lining/33.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '34', name: '34', imageSrc: webImageNamed('Fabric/Lining/34.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '36', name: '36', imageSrc: webImageNamed('Fabric/Lining/36.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '39', name: '39', imageSrc: webImageNamed('Fabric/Lining/39.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '40', name: '40', imageSrc: webImageNamed('Fabric/Lining/40.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '44', name: '44', imageSrc: webImageNamed('Fabric/Lining/44.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '45', name: '45', imageSrc: webImageNamed('Fabric/Lining/45.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '47', name: '47', imageSrc: webImageNamed('Fabric/Lining/47.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '48', name: '48', imageSrc: webImageNamed('Fabric/Lining/48.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '49', name: '49', imageSrc: webImageNamed('Fabric/Lining/49.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '50', name: '50', imageSrc: webImageNamed('Fabric/Lining/50.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '53', name: '53', imageSrc: webImageNamed('Fabric/Lining/53.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '54', name: '54', imageSrc: webImageNamed('Fabric/Lining/54.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '56', name: '56', imageSrc: webImageNamed('Fabric/Lining/56.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '59', name: '59', imageSrc: webImageNamed('Fabric/Lining/59.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '60', name: '60', imageSrc: webImageNamed('Fabric/Lining/60.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '63', name: '63', imageSrc: webImageNamed('Fabric/Lining/63.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '64', name: '64', imageSrc: webImageNamed('Fabric/Lining/64.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '67', name: '67', imageSrc: webImageNamed('Fabric/Lining/67.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '68', name: '68', imageSrc: webImageNamed('Fabric/Lining/68.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '70', name: '70', imageSrc: webImageNamed('Fabric/Lining/70.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '71', name: '71', imageSrc: webImageNamed('Fabric/Lining/71.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '75', name: '75', imageSrc: webImageNamed('Fabric/Lining/75.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '76', name: '76', imageSrc: webImageNamed('Fabric/Lining/76.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '78', name: '78', imageSrc: webImageNamed('Fabric/Lining/78.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '81', name: '81', imageSrc: webImageNamed('Fabric/Lining/81.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '83', name: '83', imageSrc: webImageNamed('Fabric/Lining/83.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '97', name: '97', imageSrc: webImageNamed('Fabric/Lining/97.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '101', name: '101', imageSrc: webImageNamed('Fabric/Lining/101.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '102', name: '102', imageSrc: webImageNamed('Fabric/Lining/102.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '103', name: '103', imageSrc: webImageNamed('Fabric/Lining/103.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '106', name: '106', imageSrc: webImageNamed('Fabric/Lining/106.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '108', name: '108', imageSrc: webImageNamed('Fabric/Lining/108.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '109', name: '109', imageSrc: webImageNamed('Fabric/Lining/109.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '111', name: '111', imageSrc: webImageNamed('Fabric/Lining/111.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '112', name: '112', imageSrc: webImageNamed('Fabric/Lining/112.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '114', name: '114', imageSrc: webImageNamed('Fabric/Lining/114.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '122', name: '122', imageSrc: webImageNamed('Fabric/Lining/122.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '125', name: '125', imageSrc: webImageNamed('Fabric/Lining/125.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '128', name: '128', imageSrc: webImageNamed('Fabric/Lining/128.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '129', name: '129', imageSrc: webImageNamed('Fabric/Lining/129.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '130', name: '130', imageSrc: webImageNamed('Fabric/Lining/130.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '131', name: '131', imageSrc: webImageNamed('Fabric/Lining/131.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '133', name: '133', imageSrc: webImageNamed('Fabric/Lining/133.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '134', name: '134', imageSrc: webImageNamed('Fabric/Lining/134.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '139', name: '139', imageSrc: webImageNamed('Fabric/Lining/139.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '143', name: '143', imageSrc: webImageNamed('Fabric/Lining/143.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '144', name: '144', imageSrc: webImageNamed('Fabric/Lining/144.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '145', name: '145', imageSrc: webImageNamed('Fabric/Lining/145.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '146', name: '146', imageSrc: webImageNamed('Fabric/Lining/146.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '147', name: '147', imageSrc: webImageNamed('Fabric/Lining/147.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '148', name: '148', imageSrc: webImageNamed('Fabric/Lining/148.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '151', name: '151', imageSrc: webImageNamed('Fabric/Lining/151.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '154', name: '154', imageSrc: webImageNamed('Fabric/Lining/154.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '157', name: '157', imageSrc: webImageNamed('Fabric/Lining/157.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '181', name: '181', imageSrc: webImageNamed('Fabric/Lining/181.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '182', name: '182', imageSrc: webImageNamed('Fabric/Lining/182.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '187', name: '187', imageSrc: webImageNamed('Fabric/Lining/187.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '190', name: '190', imageSrc: webImageNamed('Fabric/Lining/190.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '192', name: '192', imageSrc: webImageNamed('Fabric/Lining/192.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '201', name: '201', imageSrc: webImageNamed('Fabric/Lining/201.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '202', name: '202', imageSrc: webImageNamed('Fabric/Lining/202.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '212', name: '212', imageSrc: webImageNamed('Fabric/Lining/212.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '219', name: '219', imageSrc: webImageNamed('Fabric/Lining/219.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '220', name: '220', imageSrc: webImageNamed('Fabric/Lining/220.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '225', name: '225', imageSrc: webImageNamed('Fabric/Lining/225.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '226', name: '226', imageSrc: webImageNamed('Fabric/Lining/226.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '227', name: '227', imageSrc: webImageNamed('Fabric/Lining/227.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '228', name: '228', imageSrc: webImageNamed('Fabric/Lining/228.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '231', name: '231', imageSrc: webImageNamed('Fabric/Lining/231.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '232', name: '232', imageSrc: webImageNamed('Fabric/Lining/232.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '234', name: '234', imageSrc: webImageNamed('Fabric/Lining/234.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '237', name: '237', imageSrc: webImageNamed('Fabric/Lining/237.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '238', name: '238', imageSrc: webImageNamed('Fabric/Lining/238.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '239', name: '239', imageSrc: webImageNamed('Fabric/Lining/239.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '240', name: '240', imageSrc: webImageNamed('Fabric/Lining/240.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '241', name: '241', imageSrc: webImageNamed('Fabric/Lining/241.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '243', name: '243', imageSrc: webImageNamed('Fabric/Lining/243.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '245', name: '245', imageSrc: webImageNamed('Fabric/Lining/245.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '246', name: '246', imageSrc: webImageNamed('Fabric/Lining/246.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '247', name: '247', imageSrc: webImageNamed('Fabric/Lining/247.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '248', name: '248', imageSrc: webImageNamed('Fabric/Lining/248.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '254', name: '254', imageSrc: webImageNamed('Fabric/Lining/254.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '255', name: '255', imageSrc: webImageNamed('Fabric/Lining/255.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '257', name: '257', imageSrc: webImageNamed('Fabric/Lining/257.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '259', name: '259', imageSrc: webImageNamed('Fabric/Lining/259.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '261', name: '261', imageSrc: webImageNamed('Fabric/Lining/261.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '262', name: '262', imageSrc: webImageNamed('Fabric/Lining/262.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '267', name: '267', imageSrc: webImageNamed('Fabric/Lining/267.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '268', name: '268', imageSrc: webImageNamed('Fabric/Lining/268.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '269', name: '269', imageSrc: webImageNamed('Fabric/Lining/269.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '270', name: '270', imageSrc: webImageNamed('Fabric/Lining/270.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '271', name: '271', imageSrc: webImageNamed('Fabric/Lining/271.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '272', name: '272', imageSrc: webImageNamed('Fabric/Lining/272.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '273', name: '273', imageSrc: webImageNamed('Fabric/Lining/273.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '274', name: '274', imageSrc: webImageNamed('Fabric/Lining/274.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '275', name: '275', imageSrc: webImageNamed('Fabric/Lining/275.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '276', name: '276', imageSrc: webImageNamed('Fabric/Lining/276.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '277', name: '277', imageSrc: webImageNamed('Fabric/Lining/277.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '278', name: '278', imageSrc: webImageNamed('Fabric/Lining/278.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '289', name: '289', imageSrc: webImageNamed('Fabric/Lining/289.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '290', name: '290', imageSrc: webImageNamed('Fabric/Lining/290.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '291', name: '291', imageSrc: webImageNamed('Fabric/Lining/291.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '292', name: '292', imageSrc: webImageNamed('Fabric/Lining/292.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '293', name: '293', imageSrc: webImageNamed('Fabric/Lining/293.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '294', name: '294', imageSrc: webImageNamed('Fabric/Lining/294.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '295', name: '295', imageSrc: webImageNamed('Fabric/Lining/295.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '296', name: '296', imageSrc: webImageNamed('Fabric/Lining/296.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '297', name: '297', imageSrc: webImageNamed('Fabric/Lining/297.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '298', name: '298', imageSrc: webImageNamed('Fabric/Lining/298.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '299', name: '299', imageSrc: webImageNamed('Fabric/Lining/299.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '300', name: '300', imageSrc: webImageNamed('Fabric/Lining/300.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '301', name: '301', imageSrc: webImageNamed('Fabric/Lining/301.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '302', name: '302', imageSrc: webImageNamed('Fabric/Lining/302.jpg'), price: null, company: '' },
        { optType: "Fabric Lining", key: '303', name: '303', imageSrc: webImageNamed('Fabric/Lining/303.jpg'), price: null, company: '' },
    ]
    fabricLining_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Vest Lining',
            key: this.STEP_KEYS.fabricLining,
            opts: this.fabricLining_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // SUIT BUTTONS
    buttons_Opts = [
    // { optType: "Buttons", key: 'B01', name: 'B01', imageSrc: webImageNamed('Fabric/SuitButton/B01.jpg'), price: null, company: '' },
    {optType:"Buttons", key: 'SAME AS SUIT', name: 'SAME AS SUIT', imageSrc: null  , price:null , company:''},
    {optType:"Buttons", key: 'B01', name: 'B01', imageSrc: webImageNamed('Fabric/SuitButton/B01.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B02', name: 'B02', imageSrc: webImageNamed('Fabric/SuitButton/B02.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B03', name: 'B03', imageSrc: webImageNamed('Fabric/SuitButton/B03.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B04', name: 'B04', imageSrc: webImageNamed('Fabric/SuitButton/B04.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B05', name: 'B05', imageSrc: webImageNamed('Fabric/SuitButton/B05.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B06', name: 'B06', imageSrc: webImageNamed('Fabric/SuitButton/B06.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B07', name: 'B07', imageSrc: webImageNamed('Fabric/SuitButton/B07.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B08', name: 'B08', imageSrc: webImageNamed('Fabric/SuitButton/B08.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B09', name: 'B09', imageSrc: webImageNamed('Fabric/SuitButton/B09.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B10', name: 'B10', imageSrc: webImageNamed('Fabric/SuitButton/B10.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B11', name: 'B11', imageSrc: webImageNamed('Fabric/SuitButton/B11.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B12', name: 'B12', imageSrc: webImageNamed('Fabric/SuitButton/B12.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B13', name: 'B13', imageSrc: webImageNamed('Fabric/SuitButton/B13.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B14', name: 'B14', imageSrc: webImageNamed('Fabric/SuitButton/B14.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B15', name: 'B15', imageSrc: webImageNamed('Fabric/SuitButton/B15.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B16', name: 'B16', imageSrc: webImageNamed('Fabric/SuitButton/B16.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B17', name: 'B17', imageSrc: webImageNamed('Fabric/SuitButton/B17.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B18', name: 'B18', imageSrc: webImageNamed('Fabric/SuitButton/B18.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B19', name: 'B19', imageSrc: webImageNamed('Fabric/SuitButton/B19.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B20', name: 'B20', imageSrc: webImageNamed('Fabric/SuitButton/B20.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B21', name: 'B21', imageSrc: webImageNamed('Fabric/SuitButton/B21.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B22', name: 'B22', imageSrc: webImageNamed('Fabric/SuitButton/B22.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B23', name: 'B23', imageSrc: webImageNamed('Fabric/SuitButton/B23.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B24', name: 'B24', imageSrc: webImageNamed('Fabric/SuitButton/B24.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B25', name: 'B25', imageSrc: webImageNamed('Fabric/SuitButton/B25.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B26', name: 'B26', imageSrc: webImageNamed('Fabric/SuitButton/B26.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B27', name: 'B27', imageSrc: webImageNamed('Fabric/SuitButton/B27.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B28', name: 'B28', imageSrc: webImageNamed('Fabric/SuitButton/B28.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B29', name: 'B29', imageSrc: webImageNamed('Fabric/SuitButton/B29.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B30', name: 'B30', imageSrc: webImageNamed('Fabric/SuitButton/B30.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B31', name: 'B31', imageSrc: webImageNamed('Fabric/SuitButton/B31.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B32', name: 'B32', imageSrc: webImageNamed('Fabric/SuitButton/B32.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B33', name: 'B33', imageSrc: webImageNamed('Fabric/SuitButton/B33.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B34', name: 'B34', imageSrc: webImageNamed('Fabric/SuitButton/B34.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B35', name: 'B35', imageSrc: webImageNamed('Fabric/SuitButton/B35.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B36', name: 'B36', imageSrc: webImageNamed('Fabric/SuitButton/B36.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B37', name: 'B37', imageSrc: webImageNamed('Fabric/SuitButton/B37.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B38', name: 'B38', imageSrc: webImageNamed('Fabric/SuitButton/B38.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B39', name: 'B39', imageSrc: webImageNamed('Fabric/SuitButton/B39.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B40', name: 'B40', imageSrc: webImageNamed('Fabric/SuitButton/B40.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B41', name: 'B41', imageSrc: webImageNamed('Fabric/SuitButton/B41.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B42', name: 'B42', imageSrc: webImageNamed('Fabric/SuitButton/B42.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B43', name: 'B43', imageSrc: webImageNamed('Fabric/SuitButton/B43.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B44', name: 'B44', imageSrc: webImageNamed('Fabric/SuitButton/B44.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B45', name: 'B45', imageSrc: webImageNamed('Fabric/SuitButton/B45.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B46', name: 'B46', imageSrc: webImageNamed('Fabric/SuitButton/B46.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B47', name: 'B47', imageSrc: webImageNamed('Fabric/SuitButton/B47.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B48', name: 'B48', imageSrc: webImageNamed('Fabric/SuitButton/B48.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B49', name: 'B49', imageSrc: webImageNamed('Fabric/SuitButton/B49.jpg')  , price:null , company:''},
    {optType:"Buttons", key: 'B50', name: 'B50', imageSrc: webImageNamed('Fabric/SuitButton/B50.jpg')  , price:null , company:''},
]
buttons_Step = () => {
    // Change images here with respect to button choices
    return {
        name: 'Choose Buttons',
        key: this.STEP_KEYS.buttons,
        opts: this.buttons_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '3by4',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}





    // TONE, ["WAIST COAT"], []
    // TONE
    toneOpts = [
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.ChooseForMe, name: 'SAME AS SUIT', price:250, imageSrc: null },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.AllSeasons, name: 'All Seasons', imageSrc: webImageNamed('Fabric/SuitFabric/CategoryImages/AllSeason.jpg') },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.AllSeasonsPremium, name: 'All Seasons Premium', imageSrc: webImageNamed('Fabric/SuitFabric/CategoryImages/AllSeasonPremiuim.jpg') },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.FabricMerinoWool, name: 'Fabric Merino Wool', imageSrc: webImageNamed('Fabric/SuitFabric/CategoryImages/MerinoWool.jpg') },
        // { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.JacquardFabrics, name: 'Jacquard Fabrics', imageSrc: webImageNamed('Fabric/FabricCategory/JackardFabric.jpg') },
        // { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.WaterResistant, name: 'Water Resistant (All Seasons)', imageSrc: webImageNamed('Fabric/FabricCategory/WaterResistant.jpg') },
        // { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.TuxedoContrast, name: 'Tuxedo Contrast', imageSrc: webImageNamed('jacket/FABRICS/Stripes.jpg') },
    ]
    toneStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'What Category are we looking for?',
            key: this.STEP_KEYS.tone,
            opts: this.toneOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



        // VEST BACK        https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/WaistCoat/VestBack/FullFabric.jpg
        vestBack_Opts = [
            { optType: "VEST BACK", styleSelectionId: "", key: 'FULL FABRIC', name: 'FULL FABRIC', imageSrc: webImageNamed('StyleSelections/WaistCoat/VestBack/FullFabric.jpg') },
            { optType: "VEST BACK", styleSelectionId: "", key: 'LINING ON BACK', name: 'LINING ON BACK', imageSrc: webImageNamed('StyleSelections/WaistCoat/VestBack/LiningBack.jpg') },
        ]
        vestBack_Step = () => {
            return {
                name: 'Back Style',
                key: this.STEP_KEYS.vestBack,
                opts: this.vestBack_Opts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '1by1',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }
        


        // VEST BACK LINING      
        vestBackLiningFabricOpts = [
            {optType:"VEST BACK LINING", key: 'SAME AS SUIT', name: 'SAME AS SUIT', imageSrc: null  , price:null , company:''},
            // {optType:"VEST BACK LINING", key: 'SAME AS PANT', name: 'SAME AS PANT', imageSrc: null  , price:null , company:''},

            {optType:"VEST BACK LINING", key: 'A01', name: 'A01', imageSrc: webImageNamed('Fabric/SuitLining/A01.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A02', name: 'A02', imageSrc: webImageNamed('Fabric/SuitLining/A02.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A03', name: 'A03', imageSrc: webImageNamed('Fabric/SuitLining/A03.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A04', name: 'A04', imageSrc: webImageNamed('Fabric/SuitLining/A04.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A05', name: 'A05', imageSrc: webImageNamed('Fabric/SuitLining/A05.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A06', name: 'A06', imageSrc: webImageNamed('Fabric/SuitLining/A06.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A07', name: 'A07', imageSrc: webImageNamed('Fabric/SuitLining/A07.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A08', name: 'A08', imageSrc: webImageNamed('Fabric/SuitLining/A08.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A09', name: 'A09', imageSrc: webImageNamed('Fabric/SuitLining/A09.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A10', name: 'A10', imageSrc: webImageNamed('Fabric/SuitLining/A10.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A11', name: 'A11', imageSrc: webImageNamed('Fabric/SuitLining/A11.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A12', name: 'A12', imageSrc: webImageNamed('Fabric/SuitLining/A12.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A13', name: 'A13', imageSrc: webImageNamed('Fabric/SuitLining/A13.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A14', name: 'A14', imageSrc: webImageNamed('Fabric/SuitLining/A14.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A15', name: 'A15', imageSrc: webImageNamed('Fabric/SuitLining/A15.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A16', name: 'A16', imageSrc: webImageNamed('Fabric/SuitLining/A16.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A17', name: 'A17', imageSrc: webImageNamed('Fabric/SuitLining/A17.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A18', name: 'A18', imageSrc: webImageNamed('Fabric/SuitLining/A18.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A19', name: 'A19', imageSrc: webImageNamed('Fabric/SuitLining/A19.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A20', name: 'A20', imageSrc: webImageNamed('Fabric/SuitLining/A20.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A21', name: 'A21', imageSrc: webImageNamed('Fabric/SuitLining/A21.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A22', name: 'A22', imageSrc: webImageNamed('Fabric/SuitLining/A22.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A23', name: 'A23', imageSrc: webImageNamed('Fabric/SuitLining/A23.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A24', name: 'A24', imageSrc: webImageNamed('Fabric/SuitLining/A24.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A25', name: 'A25', imageSrc: webImageNamed('Fabric/SuitLining/A25.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A26', name: 'A26', imageSrc: webImageNamed('Fabric/SuitLining/A26.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A27', name: 'A27', imageSrc: webImageNamed('Fabric/SuitLining/A27.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A28', name: 'A28', imageSrc: webImageNamed('Fabric/SuitLining/A28.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A29', name: 'A29', imageSrc: webImageNamed('Fabric/SuitLining/A29.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A30', name: 'A30', imageSrc: webImageNamed('Fabric/SuitLining/A30.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A31', name: 'A31', imageSrc: webImageNamed('Fabric/SuitLining/A31.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A32', name: 'A32', imageSrc: webImageNamed('Fabric/SuitLining/A32.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A33', name: 'A33', imageSrc: webImageNamed('Fabric/SuitLining/A33.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A34', name: 'A34', imageSrc: webImageNamed('Fabric/SuitLining/A34.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A35', name: 'A35', imageSrc: webImageNamed('Fabric/SuitLining/A35.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A36', name: 'A36', imageSrc: webImageNamed('Fabric/SuitLining/A36.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A37', name: 'A37', imageSrc: webImageNamed('Fabric/SuitLining/A37.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A38', name: 'A38', imageSrc: webImageNamed('Fabric/SuitLining/A38.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A39', name: 'A39', imageSrc: webImageNamed('Fabric/SuitLining/A39.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A40', name: 'A40', imageSrc: webImageNamed('Fabric/SuitLining/A40.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A41', name: 'A41', imageSrc: webImageNamed('Fabric/SuitLining/A41.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A42', name: 'A42', imageSrc: webImageNamed('Fabric/SuitLining/A42.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A43', name: 'A43', imageSrc: webImageNamed('Fabric/SuitLining/A43.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A44', name: 'A44', imageSrc: webImageNamed('Fabric/SuitLining/A44.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A45', name: 'A45', imageSrc: webImageNamed('Fabric/SuitLining/A45.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A46', name: 'A46', imageSrc: webImageNamed('Fabric/SuitLining/A46.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A47', name: 'A47', imageSrc: webImageNamed('Fabric/SuitLining/A47.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A48', name: 'A48', imageSrc: webImageNamed('Fabric/SuitLining/A48.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A49', name: 'A49', imageSrc: webImageNamed('Fabric/SuitLining/A49.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A50', name: 'A50', imageSrc: webImageNamed('Fabric/SuitLining/A50.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A51', name: 'A51', imageSrc: webImageNamed('Fabric/SuitLining/A51.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A52', name: 'A52', imageSrc: webImageNamed('Fabric/SuitLining/A52.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A53', name: 'A53', imageSrc: webImageNamed('Fabric/SuitLining/A53.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A54', name: 'A54', imageSrc: webImageNamed('Fabric/SuitLining/A54.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A55', name: 'A55', imageSrc: webImageNamed('Fabric/SuitLining/A55.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A56', name: 'A56', imageSrc: webImageNamed('Fabric/SuitLining/A56.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A57', name: 'A57', imageSrc: webImageNamed('Fabric/SuitLining/A57.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A58', name: 'A58', imageSrc: webImageNamed('Fabric/SuitLining/A58.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A59', name: 'A59', imageSrc: webImageNamed('Fabric/SuitLining/A59.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A60', name: 'A60', imageSrc: webImageNamed('Fabric/SuitLining/A60.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A61', name: 'A61', imageSrc: webImageNamed('Fabric/SuitLining/A61.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A62', name: 'A62', imageSrc: webImageNamed('Fabric/SuitLining/A62.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A63', name: 'A63', imageSrc: webImageNamed('Fabric/SuitLining/A63.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A64', name: 'A64', imageSrc: webImageNamed('Fabric/SuitLining/A64.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A65', name: 'A65', imageSrc: webImageNamed('Fabric/SuitLining/A65.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A66', name: 'A66', imageSrc: webImageNamed('Fabric/SuitLining/A66.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A67', name: 'A67', imageSrc: webImageNamed('Fabric/SuitLining/A67.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A68', name: 'A68', imageSrc: webImageNamed('Fabric/SuitLining/A68.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A69', name: 'A69', imageSrc: webImageNamed('Fabric/SuitLining/A69.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A70', name: 'A70', imageSrc: webImageNamed('Fabric/SuitLining/A70.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A71', name: 'A71', imageSrc: webImageNamed('Fabric/SuitLining/A71.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A72', name: 'A72', imageSrc: webImageNamed('Fabric/SuitLining/A72.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A73', name: 'A73', imageSrc: webImageNamed('Fabric/SuitLining/A73.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A74', name: 'A74', imageSrc: webImageNamed('Fabric/SuitLining/A74.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A75', name: 'A75', imageSrc: webImageNamed('Fabric/SuitLining/A75.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A76', name: 'A76', imageSrc: webImageNamed('Fabric/SuitLining/A76.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A77', name: 'A77', imageSrc: webImageNamed('Fabric/SuitLining/A77.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A78', name: 'A78', imageSrc: webImageNamed('Fabric/SuitLining/A78.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A79', name: 'A79', imageSrc: webImageNamed('Fabric/SuitLining/A79.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A80', name: 'A80', imageSrc: webImageNamed('Fabric/SuitLining/A80.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A81', name: 'A81', imageSrc: webImageNamed('Fabric/SuitLining/A81.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A82', name: 'A82', imageSrc: webImageNamed('Fabric/SuitLining/A82.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A83', name: 'A83', imageSrc: webImageNamed('Fabric/SuitLining/A83.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A84', name: 'A84', imageSrc: webImageNamed('Fabric/SuitLining/A84.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A85', name: 'A85', imageSrc: webImageNamed('Fabric/SuitLining/A85.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A86', name: 'A86', imageSrc: webImageNamed('Fabric/SuitLining/A86.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A87', name: 'A87', imageSrc: webImageNamed('Fabric/SuitLining/A87.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A88', name: 'A88', imageSrc: webImageNamed('Fabric/SuitLining/A88.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A91', name: 'A91', imageSrc: webImageNamed('Fabric/SuitLining/A91.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A92', name: 'A92', imageSrc: webImageNamed('Fabric/SuitLining/A92.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A93', name: 'A93', imageSrc: webImageNamed('Fabric/SuitLining/A93.jpg')  , price:null , company:''},
            {optType:"VEST BACK LINING", key: 'A94', name: 'A94', imageSrc: webImageNamed('Fabric/SuitLining/A94.jpg')  , price:null , company:''},
        ]


        vestBackLining_Step = () => {
            return {
                name: 'Select Back Lining Fabric',
                key: this.STEP_KEYS.vestBackLining,
                opts: this.vestBackLiningFabricOpts,
                selectedOptKey: null,
                isPhotoBox: true,
                photoRatio: '1by1',
                skipable: false,
                paddingLess: false,
                boldText: false
            }
        }
    



    // FABRIC, ["WAIST COAT"], []
 
fabric_AllSeasons1 = [
    { optType: "Fabric", key: 'E5101-38', name: 'E5101-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5101-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5102-38', name: 'E5102-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5102-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5103-38', name: 'E5103-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5103-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5104-38', name: 'E5104-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5104-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5105-38', name: 'E5105-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5105-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5106-38', name: 'E5106-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5106-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5107-38', name: 'E5107-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5107-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5108-38', name: 'E5108-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5108-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5109-38', name: 'E5109-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5109-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5110-38', name: 'E5110-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5110-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5111-38', name: 'E5111-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5111-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5112-38', name: 'E5112-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5112-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5113-38', name: 'E5113-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5113-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5114-38', name: 'E5114-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5114-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5115-38', name: 'E5115-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5115-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5116-38', name: 'E5116-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5116-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5117-38', name: 'E5117-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5117-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5118-38', name: 'E5118-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5118-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5119-38', name: 'E5119-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5119-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5120-38', name: 'E5120-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5120-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5121-38', name: 'E5121-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5121-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5122-38', name: 'E5122-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5122-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5123-38', name: 'E5123-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5123-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5124-38', name: 'E5124-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5124-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5125-38', name: 'E5125-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5125-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5126-38', name: 'E5126-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5126-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5127-38', name: 'E5127-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5127-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5128-38', name: 'E5128-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5128-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5129-38', name: 'E5129-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5129-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5130-38', name: 'E5130-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5130-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5131-38', name: 'E5131-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5131-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5132-38', name: 'E5132-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5132-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5133-38', name: 'E5133-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5133-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5134-38', name: 'E5134-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5134-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5135-38', name: 'E5135-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5135-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5136-38', name: 'E5136-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5136-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5137-38', name: 'E5137-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5137-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5138-38', name: 'E5138-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5138-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5139-38', name: 'E5139-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5139-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5140-38', name: 'E5140-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5140-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5141-36', name: 'E5141-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5141-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5142-35', name: 'E5142-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5142-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5143-35', name: 'E5143-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5143-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5144-35', name: 'E5144-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5144-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5145-38', name: 'E5145-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5145-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5146-38', name: 'E5146-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5146-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5147-38', name: 'E5147-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5147-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5148-38', name: 'E5148-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5148-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5149-38', name: 'E5149-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5149-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5150-48', name: 'E5150-48', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5150-48.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5151-38', name: 'E5151-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5151-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5152-36', name: 'E5152-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5152-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5153-36', name: 'E5153-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5153-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5154-40', name: 'E5154-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5154-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5155-40', name: 'E5155-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5155-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5156-40', name: 'E5156-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5156-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5157-40', name: 'E5157-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5157-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5158-38', name: 'E5158-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5158-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5159-40', name: 'E5159-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5159-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5160-40', name: 'E5160-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5160-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5161-40', name: 'E5161-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5161-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5162-40', name: 'E5162-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5162-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5163-40', name: 'E5163-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5163-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5164-40', name: 'E5164-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5164-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5166-40', name: 'E5166-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5166-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5167-40', name: 'E5167-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5167-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5168-40', name: 'E5168-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5168-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5169-40', name: 'E5169-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5169-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5170-40', name: 'E5170-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5170-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5172-40', name: 'E5172-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5172-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5173-40', name: 'E5173-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5173-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5174-40', name: 'E5174-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5174-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5175-40', name: 'E5175-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5175-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5176-40', name: 'E5176-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5176-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5177-40', name: 'E5177-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5177-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5178-40', name: 'E5178-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5178-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5179-40', name: 'E5179-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5179-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5180-40', name: 'E5180-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5180-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5181-40', name: 'E5181-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5181-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5201-35', name: 'E5201-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5201-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5202-35', name: 'E5202-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5202-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5203-35', name: 'E5203-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5203-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5204-35', name: 'E5204-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5204-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5205-35', name: 'E5205-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5205-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5206-38', name: 'E5206-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5206-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5207-35', name: 'E5207-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5207-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5208-35', name: 'E5208-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5208-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5209-35', name: 'E5209-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5209-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5210-35', name: 'E5210-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5210-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5211-35', name: 'E5211-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5211-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5212-35', name: 'E5212-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5212-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5213-38', name: 'E5213-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5213-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5214-38', name: 'E5214-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5214-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5215-35', name: 'E5215-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5215-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5216-35', name: 'E5216-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5216-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5217-35', name: 'E5217-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5217-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5218-35', name: 'E5218-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5218-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5219-35', name: 'E5219-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5219-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5220-35', name: 'E5220-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5220-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5221-38', name: 'E5221-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5221-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5222-38', name: 'E5222-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5222-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5223-38', name: 'E5223-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5223-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5224-38', name: 'E5224-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5224-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5225-38', name: 'E5225-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5225-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5226-38', name: 'E5226-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5226-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5228-38', name: 'E5228-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5228-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5229-38', name: 'E5229-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5229-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5230-35', name: 'E5230-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5230-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5231-35', name: 'E5231-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5231-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5232-35', name: 'E5232-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5232-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5233-35', name: 'E5233-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5233-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5234-35', name: 'E5234-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5234-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5235-35', name: 'E5235-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5235-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5236-35', name: 'E5236-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5236-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5237-35', name: 'E5237-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5237-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5238-35', name: 'E5238-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5238-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5239-35', name: 'E5239-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5239-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5240-35', name: 'E5240-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5240-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5241-35', name: 'E5241-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5241-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5242-35', name: 'E5242-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5242-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5244-35', name: 'E5244-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5244-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5245-35', name: 'E5245-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5245-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5246-35', name: 'E5246-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5246-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5247-35', name: 'E5247-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5247-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5248-35', name: 'E5248-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5248-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5249-35', name: 'E5249-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5249-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5250-38', name: 'E5250-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5250-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5251-35', name: 'E5251-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5251-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5252-35', name: 'E5252-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5252-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5253-35', name: 'E5253-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5253-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5254-35', name: 'E5254-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5254-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5255-35', name: 'E5255-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5255-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5256-35', name: 'E5256-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5256-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5257-35', name: 'E5257-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5257-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5258-38', name: 'E5258-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5258-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5259-38', name: 'E5259-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5259-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5261-38', name: 'E5261-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5261-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5262-38', name: 'E5262-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5262-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5263-38', name: 'E5263-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5263-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5264-38', name: 'E5264-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5264-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5265-38', name: 'E5265-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5265-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5266-38', name: 'E5266-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5266-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5267-38', name: 'E5267-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5267-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5268-38', name: 'E5268-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5268-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5269-40', name: 'E5269-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5269-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5270-40', name: 'E5270-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5270-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5271-40', name: 'E5271-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5271-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5272-40', name: 'E5272-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5272-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5301-38', name: 'E5301-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5301-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5302-38', name: 'E5302-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5302-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5303-38', name: 'E5303-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5303-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5304-38', name: 'E5304-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5304-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5305-38', name: 'E5305-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5305-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5306-38', name: 'E5306-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5306-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5307-38', name: 'E5307-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5307-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5308-38', name: 'E5308-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5308-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5309-36', name: 'E5309-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5309-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5310-36', name: 'E5310-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5310-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5311-36', name: 'E5311-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5311-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5312-36', name: 'E5312-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5312-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5313-36', name: 'E5313-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5313-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5314-36', name: 'E5314-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5314-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5315-33', name: 'E5315-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5315-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5316-35', name: 'E5316-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5316-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5317-35', name: 'E5317-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5317-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5318-35', name: 'E5318-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5318-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5319-33', name: 'E5319-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5319-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5320-33', name: 'E5320-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5320-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5321-33', name: 'E5321-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5321-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5322-33', name: 'E5322-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5322-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5323-33', name: 'E5323-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5323-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5324-33', name: 'E5324-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5324-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5325-33', name: 'E5325-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5325-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5326-33', name: 'E5326-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5326-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5327-33', name: 'E5327-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5327-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5328-38', name: 'E5328-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5328-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5329-38', name: 'E5329-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5329-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5330-38', name: 'E5330-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5330-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5331-36', name: 'E5331-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5331-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5332-36', name: 'E5332-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5332-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5333-36', name: 'E5333-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5333-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5334-36', name: 'E5334-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5334-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5335-36', name: 'E5335-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5335-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5336-36', name: 'E5336-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5336-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5337-35', name: 'E5337-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5337-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5338-33', name: 'E5338-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5338-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5340-33', name: 'E5340-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5340-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5341-32', name: 'E5341-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5341-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5342-32', name: 'E5342-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5342-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5343-32', name: 'E5343-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5343-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5344-32', name: 'E5344-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5344-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5345-32', name: 'E5345-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5345-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5346-35', name: 'E5346-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5346-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5347-38', name: 'E5347-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5347-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5348-38', name: 'E5348-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5348-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5349-38', name: 'E5349-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5349-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5351-38', name: 'E5351-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5351-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5352-38', name: 'E5352-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5352-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5353-38', name: 'E5353-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5353-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5354-38', name: 'E5354-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5354-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5355-38', name: 'E5355-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5355-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5356-38', name: 'E5356-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5356-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5357-38', name: 'E5357-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5357-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5358-38', name: 'E5358-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5358-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5359-38', name: 'E5359-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5359-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5360-40', name: 'E5360-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5360-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5361-38', name: 'E5361-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5361-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5362-40', name: 'E5362-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5362-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5363-40', name: 'E5363-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5363-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5364-40', name: 'E5364-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5364-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5365-38', name: 'E5365-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5365-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5401-38', name: 'E5401-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5401-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5403-38', name: 'E5403-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5403-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5404-38', name: 'E5404-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5404-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5405-38', name: 'E5405-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5405-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5406-38', name: 'E5406-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5406-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5407-38', name: 'E5407-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5407-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5408-38', name: 'E5408-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5408-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5409-38', name: 'E5409-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5409-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5410-38', name: 'E5410-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5410-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5411-38', name: 'E5411-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5411-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5412-38', name: 'E5412-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5412-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5413-38', name: 'E5413-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5413-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5414-38', name: 'E5414-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5414-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5415-38', name: 'E5415-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5415-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5416-38', name: 'E5416-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5416-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5417-38', name: 'E5417-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5417-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5418-38', name: 'E5418-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5418-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5419-38', name: 'E5419-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5419-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5420-38', name: 'E5420-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5420-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5421-38', name: 'E5421-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5421-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5422-38', name: 'E5422-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5422-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5423-38', name: 'E5423-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5423-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5424-38', name: 'E5424-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5424-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5425-38', name: 'E5425-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5425-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5426-38', name: 'E5426-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5426-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5427-38', name: 'E5427-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5427-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5428-38', name: 'E5428-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5428-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5429-30', name: 'E5429-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5429-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5430-30', name: 'E5430-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5430-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5431-30', name: 'E5431-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5431-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5432-30', name: 'E5432-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5432-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5433-35', name: 'E5433-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5433-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5434-35', name: 'E5434-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5434-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5435-35', name: 'E5435-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5435-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5436-35', name: 'E5436-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5436-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5437-35', name: 'E5437-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5437-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5438-42', name: 'E5438-42', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5438-42.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5439-42', name: 'E5439-42', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5439-42.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5440-42', name: 'E5440-42', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5440-42.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5441-36', name: 'E5441-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5441-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5442-36', name: 'E5442-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5442-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5443-35', name: 'E5443-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5443-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5443-36', name: 'E5443-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5443-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5444-36', name: 'E5444-36', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5444-36.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5445-35', name: 'E5445-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5445-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5446-35', name: 'E5446-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5446-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5447-35', name: 'E5447-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5447-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5448-35', name: 'E5448-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5448-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5449-35', name: 'E5449-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5449-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5450-35', name: 'E5450-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5450-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5451-35', name: 'E5451-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5451-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5452-35', name: 'E5452-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5452-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5453-35', name: 'E5453-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5453-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5454-35', name: 'E5454-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5454-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5455-35', name: 'E5455-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5455-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5456-35', name: 'E5456-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5456-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5457-35', name: 'E5457-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5457-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5458-30', name: 'E5458-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5458-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5459-30', name: 'E5459-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5459-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5460-30', name: 'E5460-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5460-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5461-30', name: 'E5461-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5461-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5462-30', name: 'E5462-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5462-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5463-30', name: 'E5463-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5463-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5464-30', name: 'E5464-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5464-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5465-30', name: 'E5465-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5465-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5466-30', name: 'E5466-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5466-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5467-30', name: 'E5467-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5467-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5468-30', name: 'E5468-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5468-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5469-30', name: 'E5469-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5469-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5470-30', name: 'E5470-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5470-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5471-30', name: 'E5471-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5471-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5472-30', name: 'E5472-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5472-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5473-30', name: 'E5473-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5473-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5474-30', name: 'E5474-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5474-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5475-30', name: 'E5475-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5475-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5476-30', name: 'E5476-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5476-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5477-35', name: 'E5477-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5477-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5478-30', name: 'E5478-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5478-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5479-38', name: 'E5479-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5479-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5480-45', name: 'E5480-45', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5480-45.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5501-30', name: 'E5501-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5501-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5502-28', name: 'E5502-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5502-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5503-28', name: 'E5503-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5503-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5504-28', name: 'E5504-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5504-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5505-28', name: 'E5505-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5505-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5506-28', name: 'E5506-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5506-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5507-28', name: 'E5507-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5507-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5508-28', name: 'E5508-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5508-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5509-28', name: 'E5509-28', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5509-28.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5510-30', name: 'E5510-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5510-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5511-30', name: 'E5511-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5511-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5512-32', name: 'E5512-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5512-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5513-32', name: 'E5513-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5513-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5514-32', name: 'E5514-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5514-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5515-32', name: 'E5515-32', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5515-32.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5516-35', name: 'E5516-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5516-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5517-35', name: 'E5517-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5517-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5518-35', name: 'E5518-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5518-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5519-35', name: 'E5519-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5519-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5520-35', name: 'E5520-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5520-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5521-35', name: 'E5521-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5521-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5522-35', name: 'E5522-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5522-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5523-35', name: 'E5523-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5523-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5524-35', name: 'E5524-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5524-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5525-35', name: 'E5525-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5525-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5526-35', name: 'E5526-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5526-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5527-35', name: 'E5527-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5527-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5528-35', name: 'E5528-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5528-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5529-35', name: 'E5529-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5529-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5530-35', name: 'E5530-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5530-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5531-35', name: 'E5531-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5531-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5532-35', name: 'E5532-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5532-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5533-40', name: 'E5533-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5533-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5534-40', name: 'E5534-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5534-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5535-40', name: 'E5535-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5535-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5536-40', name: 'E5536-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5536-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5537-40', name: 'E5537-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5537-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5538-40', name: 'E5538-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5538-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5539-40', name: 'E5539-40', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5539-40.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5540-38', name: 'E5540-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5540-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5541-38', name: 'E5541-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5541-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5542-38', name: 'E5542-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5542-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5544-38', name: 'E5544-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5544-38.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5545-35', name: 'E5545-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5545-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5546-35', name: 'E5546-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5546-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5547-35', name: 'E5547-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5547-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5548-30', name: 'E5548-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5548-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5549-30', name: 'E5549-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5549-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5550-30', name: 'E5550-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5550-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5551-35', name: 'E5551-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5551-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5552-35', name: 'E5552-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5552-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5553-35', name: 'E5553-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5553-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5554-35', name: 'E5554-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5554-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5555-35', name: 'E5555-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5555-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5556-35', name: 'E5556-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5556-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5557-35', name: 'E5557-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5557-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5558-35', name: 'E5558-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5558-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5559-33', name: 'E5559-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5559-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5560-33', name: 'E5560-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5560-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5561-33', name: 'E5561-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5561-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5562-33', name: 'E5562-33', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5562-33.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5563-30', name: 'E5563-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5563-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5564-30', name: 'E5564-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5564-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5565-30', name: 'E5565-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5565-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5566-30', name: 'E5566-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5566-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5567-30', name: 'E5567-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5567-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5568-30', name: 'E5568-30', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5568-30.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5569-35', name: 'E5569-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5569-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5570-35', name: 'E5570-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5570-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5572-35', name: 'E5572-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5572-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5573-35', name: 'E5573-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5573-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5574-35', name: 'E5574-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5574-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5575-35', name: 'E5575-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5575-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5576-35', name: 'E5576-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5576-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5577-35', name: 'E5577-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5577-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5578-35', name: 'E5578-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5578-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5579-35', name: 'E5579-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5579-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5580-35', name: 'E5580-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5580-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5581-35', name: 'E5581-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5581-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5582-35', name: 'E5582-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5582-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5583-35', name: 'E5583-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5583-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5584-35', name: 'E5584-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5584-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5585-35', name: 'E5585-35', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5585-35.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5586-68', name: 'E5586-68', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5586-68.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5587-68', name: 'E5587-68', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5587-68.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5588-68', name: 'E5588-68', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5588-68.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5589-68', name: 'E5589-68', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5589-68.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5590-68', name: 'E5590-68', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5590-68.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E5591-68', name: 'E5591-68', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E5591-68.jpg'), price: 449, company: '' },
    { optType: "Fabric", key: 'E52237-38', name: 'E52237-38', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeason/E52237-38.jpg'), price: 449, company: '' },
]

fabric_AllSeasonPremium = [
    { optType: "Fabric", key: 'K6500-1', name: 'K6500-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6500-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6500-2', name: 'K6500-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6500-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6500-3', name: 'K6500-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6500-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6500-4', name: 'K6500-4', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6500-4.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6511-1', name: 'K6511-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6511-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6511-2', name: 'K6511-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6511-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6511-3', name: 'K6511-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6511-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6522-1', name: 'K6522-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6522-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6522-2', name: 'K6522-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6522-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6522-3', name: 'K6522-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6522-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6533-1', name: 'K6533-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6533-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6533-2', name: 'K6533-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6533-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6533-3', name: 'K6533-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6533-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6555-1', name: 'K6555-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6555-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6555-2', name: 'K6555-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6555-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6555-3', name: 'K6555-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6555-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6560-1', name: 'K6560-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6560-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6560-2', name: 'K6560-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6560-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6900-1', name: 'K6900-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6900-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6900-2', name: 'K6900-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6900-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6900-3', name: 'K6900-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6900-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6910-1', name: 'K6910-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6910-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6910-2', name: 'K6910-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6910-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6910-3', name: 'K6910-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6910-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6920-1', name: 'K6920-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6920-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6920-3', name: 'K6920-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6920-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6930-1', name: 'K6930-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6930-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6930-2', name: 'K6930-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6930-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6940-1', name: 'K6940-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6940-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K6940-2', name: 'K6940-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K6940-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-1', name: 'K7800-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-2', name: 'K7800-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-3', name: 'K7800-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-6', name: 'K7800-6', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-6.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-7', name: 'K7800-7', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-7.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-8', name: 'K7800-8', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-8.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-9', name: 'K7800-9', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-9.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-10', name: 'K7800-10', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-10.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7800-11', name: 'K7800-11', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7800-11.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7801-1', name: 'K7801-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7801-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7801-2', name: 'K7801-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7801-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7801-3', name: 'K7801-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7801-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7802-1', name: 'K7802-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7802-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7802-2', name: 'K7802-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7802-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7803-1', name: 'K7803-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7803-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7803-2', name: 'K7803-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7803-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7804-1', name: 'K7804-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7804-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7804-2', name: 'K7804-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7804-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7805-1', name: 'K7805-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7805-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7807', name: 'K7807', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7807.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7807-2', name: 'K7807-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7807-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7809', name: 'K7809', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7809.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-1', name: 'K7810-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-2', name: 'K7810-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-3', name: 'K7810-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-4', name: 'K7810-4', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-4.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-6', name: 'K7810-6', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-6.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-7', name: 'K7810-7', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-7.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-8', name: 'K7810-8', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-8.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7810-9', name: 'K7810-9', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7810-9.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7812-2', name: 'K7812-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7812-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7833-1', name: 'K7833-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7833-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7833-2', name: 'K7833-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7833-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7833-4', name: 'K7833-4', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7833-4.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7844-1', name: 'K7844-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7844-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7844-2', name: 'K7844-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7844-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7855-1', name: 'K7855-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7855-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7855-2', name: 'K7855-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7855-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7855-3', name: 'K7855-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7855-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7881', name: 'K7881', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7881.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7888-1', name: 'K7888-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7888-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7888-2', name: 'K7888-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7888-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7890-1', name: 'K7890-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7890-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7890-2', name: 'K7890-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7890-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7899-1', name: 'K7899-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7899-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7899-2', name: 'K7899-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7899-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7925-1', name: 'K7925-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7925-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7925-2', name: 'K7925-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7925-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7957-1', name: 'K7957-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7957-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7957-2', name: 'K7957-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7957-2.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7957-3', name: 'K7957-3', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7957-3.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7968-1', name: 'K7968-1', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7968-1.jpg'), price: 700, company: '' },
    { optType: "Fabric", key: 'K7968-2', name: 'K7968-2', imageSrc: webImageNamed('Fabric/SuitFabric/AllSeasonPremium/K7968-2.jpg'), price: 700, company: '' },
]

fabric_MerinoWool = [
    { optType: "Fabric", key: 'MK2021B-1', name: 'MK2021B-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-1S', name: 'MK2021B-1S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-1S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-2S', name: 'MK2021B-2S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-2S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-4', name: 'MK2021B-4', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-4.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-5', name: 'MK2021B-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-6', name: 'MK2021B-6', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-6.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-8S', name: 'MK2021B-8S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-8S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-10', name: 'MK2021B-10', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-10.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-12', name: 'MK2021B-12', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-12.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-13', name: 'MK2021B-13', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-13.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-14S', name: 'MK2021B-14S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-14S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-17', name: 'MK2021B-17', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-17.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-18', name: 'MK2021B-18', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-18.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-18S', name: 'MK2021B-18S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-18S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-19S', name: 'MK2021B-19S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-19S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-20', name: 'MK2021B-20', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-20.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-20S', name: 'MK2021B-20S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-20S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-21', name: 'MK2021B-21', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-21.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-21S', name: 'MK2021B-21S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-21S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-22S', name: 'MK2021B-22S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-22S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-24', name: 'MK2021B-24', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-24.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-24S', name: 'MK2021B-24S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-24S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-26', name: 'MK2021B-26', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-26.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-26S', name: 'MK2021B-26S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-26S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-30S', name: 'MK2021B-30S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-30S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-31S', name: 'MK2021B-31S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-31S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-33S', name: 'MK2021B-33S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-33S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-34S', name: 'MK2021B-34S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-34S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-35S', name: 'MK2021B-35S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-35S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-37S', name: 'MK2021B-37S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-37S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-39', name: 'MK2021B-39', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-39.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-39S', name: 'MK2021B-39S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-39S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-40', name: 'MK2021B-40', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-40.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-40S', name: 'MK2021B-40S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-40S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-41', name: 'MK2021B-41', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-41.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-41S', name: 'MK2021B-41S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-41S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-42', name: 'MK2021B-42', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-42.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-42S', name: 'MK2021B-42S', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-42S.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-43', name: 'MK2021B-43', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-43.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-44', name: 'MK2021B-44', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-44.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-45', name: 'MK2021B-45', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-45.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-46', name: 'MK2021B-46', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-46.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-47', name: 'MK2021B-47', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-47.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021B-48', name: 'MK2021B-48', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021B-48.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-4', name: 'MK2021C-4', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-4.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-18', name: 'MK2021C-18', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-18.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-22', name: 'MK2021C-22', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-22.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-26', name: 'MK2021C-26', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-26.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-27', name: 'MK2021C-27', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-27.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-31', name: 'MK2021C-31', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-31.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-32', name: 'MK2021C-32', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-32.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-34', name: 'MK2021C-34', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-34.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-36', name: 'MK2021C-36', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-36.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-38', name: 'MK2021C-38', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-38.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-40', name: 'MK2021C-40', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-40.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-42', name: 'MK2021C-42', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-42.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-52', name: 'MK2021C-52', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-52.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-54', name: 'MK2021C-54', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-54.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-56', name: 'MK2021C-56', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-56.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-60', name: 'MK2021C-60', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-60.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-61', name: 'MK2021C-61', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-61.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-65', name: 'MK2021C-65', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-65.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-71', name: 'MK2021C-71', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-71.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021C-74', name: 'MK2021C-74', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021C-74.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-12', name: 'MK2021D-12', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-12.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-13', name: 'MK2021D-13', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-13.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-14', name: 'MK2021D-14', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-14.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-16', name: 'MK2021D-16', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-16.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-18', name: 'MK2021D-18', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-18.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-19', name: 'MK2021D-19', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-19.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-21', name: 'MK2021D-21', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-21.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-26', name: 'MK2021D-26', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-26.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021D-40', name: 'MK2021D-40', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021D-40.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-1', name: 'MK2021G-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-1A', name: 'MK2021G-1A', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-1A.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-1C', name: 'MK2021G-1C', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-1C.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-1E', name: 'MK2021G-1E', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-1E.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-1F', name: 'MK2021G-1F', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-1F.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-2', name: 'MK2021G-2', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-2.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-2A', name: 'MK2021G-2A', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-2A.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-2C', name: 'MK2021G-2C', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-2C.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-2D', name: 'MK2021G-2D', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-2D.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-3', name: 'MK2021G-3', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-3.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-4', name: 'MK2021G-4', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-4.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-5', name: 'MK2021G-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-6', name: 'MK2021G-6', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-6.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-8', name: 'MK2021G-8', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-8.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-9', name: 'MK2021G-9', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-9.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-11', name: 'MK2021G-11', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-11.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-12', name: 'MK2021G-12', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-12.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-13', name: 'MK2021G-13', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-13.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-14', name: 'MK2021G-14', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-14.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-15', name: 'MK2021G-15', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-15.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-16', name: 'MK2021G-16', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-16.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-18', name: 'MK2021G-18', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-18.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-19', name: 'MK2021G-19', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-19.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-21', name: 'MK2021G-21', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-21.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-23', name: 'MK2021G-23', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-23.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-24', name: 'MK2021G-24', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-24.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-25', name: 'MK2021G-25', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-25.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-28', name: 'MK2021G-28', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-28.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-30', name: 'MK2021G-30', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-30.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-31', name: 'MK2021G-31', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-31.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-32', name: 'MK2021G-32', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-32.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-33', name: 'MK2021G-33', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-33.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-34', name: 'MK2021G-34', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-34.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-49', name: 'MK2021G-49', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-49.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-72', name: 'MK2021G-72', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-72.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-89', name: 'MK2021G-89', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-89.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021G-99', name: 'MK2021G-99', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021G-99.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-1', name: 'MK2021H-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-2', name: 'MK2021H-2', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-2.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-3', name: 'MK2021H-3', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-3.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-4', name: 'MK2021H-4', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-4.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-5', name: 'MK2021H-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-6', name: 'MK2021H-6', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-6.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-7', name: 'MK2021H-7', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-7.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-8', name: 'MK2021H-8', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-8.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-10', name: 'MK2021H-10', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-10.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-11', name: 'MK2021H-11', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-11.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-12', name: 'MK2021H-12', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-12.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-19', name: 'MK2021H-19', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-19.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-29', name: 'MK2021H-29', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-29.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-31', name: 'MK2021H-31', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-31.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021H-33', name: 'MK2021H-33', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021H-33.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021K-1', name: 'MK2021K-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021K-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021K-5', name: 'MK2021K-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021K-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021K-6', name: 'MK2021K-6', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021K-6.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021K-7', name: 'MK2021K-7', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021K-7.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021M-1', name: 'MK2021M-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021M-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021M-2', name: 'MK2021M-2', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021M-2.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021M-3', name: 'MK2021M-3', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021M-3.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021M-5', name: 'MK2021M-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021M-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021M-6', name: 'MK2021M-6', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021M-6.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021M-8', name: 'MK2021M-8', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021M-8.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021N-1', name: 'MK2021N-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021N-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021N-1A', name: 'MK2021N-1A', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021N-1A.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021N-3', name: 'MK2021N-3', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021N-3.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021N-10', name: 'MK2021N-10', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021N-10.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021N-21', name: 'MK2021N-21', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021N-21.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021N-30', name: 'MK2021N-30', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021N-30.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021S-4', name: 'MK2021S-4', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021S-4.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021S-5', name: 'MK2021S-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021S-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021S-11', name: 'MK2021S-11', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021S-11.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021T-1', name: 'MK2021T-1', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021T-1.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021T-2', name: 'MK2021T-2', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021T-2.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021T-3', name: 'MK2021T-3', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021T-3.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021T-4', name: 'MK2021T-4', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021T-4.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MK2021T-5', name: 'MK2021T-5', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MK2021T-5.jpg'), price: 600, company: '' },
    { optType: "Fabric", key: 'MKA2021B-9', name: 'MKA2021B-9', imageSrc: webImageNamed('Fabric/SuitFabric/MerinoWool/MKA2021B-9.jpg'), price: 600, company: '' },
]

fabric_AllSeasons4 = [
    { optType: "Fabric", key: 'DBN342A', name: 'DBN342A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBN342A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBN530A', name: 'DBN530A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBN530A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBN890A', name: 'DBN890A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBN890A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBN910A', name: 'DBN910A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBN910A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR229A', name: 'DBR229A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR229A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR230A', name: 'DBR230A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR230A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR231A', name: 'DBR231A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR231A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR232A', name: 'DBR232A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR232A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR233A', name: 'DBR233A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR233A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR234A', name: 'DBR234A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR234A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBR235A', name: 'DBR235A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBR235A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS178A', name: 'DBS178A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBS178A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS179A', name: 'DBS179A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBS179A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS231A', name: 'DBS231A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBS231A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS232A', name: 'DBS232A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBS232A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS233A', name: 'DBS233A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBS233A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT557A', name: 'DBT557A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT557A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT558A', name: 'DBT558A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT558A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT570A', name: 'DBT570A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT570A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT571A', name: 'DBT571A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT571A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT586A', name: 'DBT586A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT586A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT587A', name: 'DBT587A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT587A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT589A', name: 'DBT589A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT589A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT590A', name: 'DBT590A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT590A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT1503', name: 'DBT1503', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT1503.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT1506', name: 'DBT1506', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBT1506.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU037A', name: 'DBU037A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU037A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU038A', name: 'DBU038A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU038A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU039A', name: 'DBU039A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU039A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU040A', name: 'DBU040A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU040A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU041A', name: 'DBU041A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU041A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU042A', name: 'DBU042A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU042A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU043A', name: 'DBU043A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU043A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU054A', name: 'DBU054A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU054A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU055A', name: 'DBU055A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU055A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU058A', name: 'DBU058A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU058A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU059A', name: 'DBU059A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU059A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU072A', name: 'DBU072A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU072A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU098A', name: 'DBU098A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU098A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU099A', name: 'DBU099A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU099A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU100A', name: 'DBU100A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU100A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU101A', name: 'DBU101A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU101A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU102A', name: 'DBU102A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU102A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU103A', name: 'DBU103A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU103A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU104A', name: 'DBU104A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU104A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU105A', name: 'DBU105A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU105A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU106A', name: 'DBU106A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU106A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU107A', name: 'DBU107A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU107A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU108A', name: 'DBU108A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBU108A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV013A', name: 'DBV013A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV013A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV015A', name: 'DBV015A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV015A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV016A', name: 'DBV016A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV016A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV017A', name: 'DBV017A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV017A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV018A', name: 'DBV018A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV018A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV020A', name: 'DBV020A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV020A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV021A', name: 'DBV021A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV021A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV031A', name: 'DBV031A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV031A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV032A', name: 'DBV032A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV032A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV033A', name: 'DBV033A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV033A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV034A', name: 'DBV034A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV034A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV035A', name: 'DBV035A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV035A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV036A', name: 'DBV036A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV036A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV037A', name: 'DBV037A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV037A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV074A', name: 'DBV074A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV074A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV075A', name: 'DBV075A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV075A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV076A', name: 'DBV076A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV076A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV077A', name: 'DBV077A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV077A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV0219A', name: 'DBV0219A', imageSrc: webImageNamed('Fabric/SuitfabricNo.5/DBV0219A.jpg'), price: 200, company: '' },
]

fabric_FallWinter = [
    { optType: "Fabric", key: 'DBQ662A', name: 'DBQ662A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBQ662A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS234A', name: 'DBS234A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBS234A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBS235A', name: 'DBS235A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBS235A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT559A', name: 'DBT559A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT559A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT560A', name: 'DBT560A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT560A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT561A', name: 'DBT561A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT561A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT591A', name: 'DBT591A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT591A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT592A', name: 'DBT592A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT592A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT593A', name: 'DBT593A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT593A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT594A', name: 'DBT594A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT594A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT605A', name: 'DBT605A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT605A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT606A', name: 'DBT606A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT606A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT607A', name: 'DBT607A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT607A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT610A', name: 'DBT610A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT610A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT611A', name: 'DBT611A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT611A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT612A', name: 'DBT612A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT612A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT616A', name: 'DBT616A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT616A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBT618A', name: 'DBT618A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBT618A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU008A', name: 'DBU008A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU008A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU009A', name: 'DBU009A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU009A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU010A', name: 'DBU010A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU010A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU011A', name: 'DBU011A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU011A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU012A', name: 'DBU012A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU012A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU013A', name: 'DBU013A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU013A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU014A', name: 'DBU014A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU014A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU015A', name: 'DBU015A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU015A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU016A', name: 'DBU016A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU016A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU017A', name: 'DBU017A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU017A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU090A', name: 'DBU090A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU090A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU091A', name: 'DBU091A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU091A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU092A', name: 'DBU092A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU092A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBU093A', name: 'DBU093A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBU093A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV008A', name: 'DBV008A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV008A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV009A', name: 'DBV009A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV009A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV011A', name: 'DBV011A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV011A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV012A', name: 'DBV012A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV012A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV038A', name: 'DBV038A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV038A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV039A', name: 'DBV039A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV039A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV050A', name: 'DBV050A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV050A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV051A', name: 'DBV051A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV051A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV052A', name: 'DBV052A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV052A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV053A', name: 'DBV053A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV053A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV054A', name: 'DBV054A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV054A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV055A', name: 'DBV055A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV055A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV056A', name: 'DBV056A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV056A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV057A', name: 'DBV057A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV057A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV058A', name: 'DBV058A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV058A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV080A', name: 'DBV080A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV080A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV081A', name: 'DBV081A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV081A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV082A', name: 'DBV082A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV082A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV083A', name: 'DBV083A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV083A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV084A', name: 'DBV084A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV084A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV085A', name: 'DBV085A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV085A.jpg'), price: 200, company: '' },
    { optType: "Fabric", key: 'DBV086A', name: 'DBV086A', imageSrc: webImageNamed('Fabric/FallWinterFabrics/DBV086A.jpg'), price: 200, company: '' },
]
fabric_SpringSummer = [
    {optType:"Fabric", key: '2299-1', name: '2299-1', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-1.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-2', name: '2299-2', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-2.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-3', name: '2299-3', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-3.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-4', name: '2299-4', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-4.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-5', name: '2299-5', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-5.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-6', name: '2299-6', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-6.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-7', name: '2299-7', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-7.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-8', name: '2299-8', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-8.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-9', name: '2299-9', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-9.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-10', name: '2299-10', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-10.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-11', name: '2299-11', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-11.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-13', name: '2299-13', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-13.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-101', name: '2299-101', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-101.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-102', name: '2299-102', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-102.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-103', name: '2299-103', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-103.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-104', name: '2299-104', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-104.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-107', name: '2299-107', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-107.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-108', name: '2299-108', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-108.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2299-109', name: '2299-109', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2299-109.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-1', name: '2899-1', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-1.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-2', name: '2899-2', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-2.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-3', name: '2899-3', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-3.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-4', name: '2899-4', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-4.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-5', name: '2899-5', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-5.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-8', name: '2899-8', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-8.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-9', name: '2899-9', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-9.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-10', name: '2899-10', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-10.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-11', name: '2899-11', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-11.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-12', name: '2899-12', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-12.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-13', name: '2899-13', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-13.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-101', name: '2899-101', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-101.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-102', name: '2899-102', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-102.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-103', name: '2899-103', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-103.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-105', name: '2899-105', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-105.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-106', name: '2899-106', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-106.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-107', name: '2899-107', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-107.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '2899-108', name: '2899-108', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/SpringAndSummer900/2899-108.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'Y01', name: 'Y01', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y01.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y10', name: 'Y10', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y10.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y12', name: 'Y12', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y12.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y13', name: 'Y13', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y13.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y14', name: 'Y14', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y14.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y23', name: 'Y23', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y23.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y26', name: 'Y26', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y26.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y27', name: 'Y27', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y27.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y33', name: 'Y33', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y33.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y40', name: 'Y40', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y40.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y41', name: 'Y41', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y41.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y46', name: 'Y46', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y46.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y48', name: 'Y48', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y48.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y54', name: 'Y54', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y54.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y62', name: 'Y62', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y62.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y65', name: 'Y65', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y65.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y68', name: 'Y68', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y68.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y70', name: 'Y70', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y70.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y71', name: 'Y71', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y71.jpg')  , price:300 , company:'Gresham Bloke Linen'},
    {optType:"Fabric", key: 'Y72', name: 'Y72', imageSrc: webImageNamed('Fabric/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y72.jpg')  , price:300 , company:'Gresham Bloke Linen'},
]
fabric_JacquardFabrics = [
    {optType:"Fabric", key: 'JF1001', name: 'JF1001', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1001.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1002', name: 'JF1002', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1002.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1003', name: 'JF1003', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1003.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1004', name: 'JF1004', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1004.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1005', name: 'JF1005', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1005.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1006', name: 'JF1006', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1006.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1007', name: 'JF1007', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1007.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1008', name: 'JF1008', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1008.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1009', name: 'JF1009', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1009.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1010', name: 'JF1010', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1010.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1011', name: 'JF1011', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1011.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1012', name: 'JF1012', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1012.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1013', name: 'JF1013', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1013.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1014', name: 'JF1014', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1014.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1015', name: 'JF1015', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1015.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1016', name: 'JF1016', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1016.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1017', name: 'JF1017', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1017.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1018', name: 'JF1018', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1018.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1019', name: 'JF1019', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1019.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1021', name: 'JF1021', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1021.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1022', name: 'JF1022', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1022.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1023', name: 'JF1023', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1023.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1024', name: 'JF1024', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1024.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1025', name: 'JF1025', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1025.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1026', name: 'JF1026', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1026.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1027', name: 'JF1027', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1027.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1028', name: 'JF1028', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1028.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1029', name: 'JF1029', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1029.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1030', name: 'JF1030', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1030.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1031', name: 'JF1031', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1031.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1032', name: 'JF1032', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/JF1032.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1033', name: 'JF1033', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1033.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1034', name: 'JF1034', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1034.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1035', name: 'JF1035', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1035.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1036', name: 'JF1036', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1036.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1037', name: 'JF1037', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1037.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1038', name: 'JF1038', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1038.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1039', name: 'JF1039', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1039.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1040', name: 'JF1040', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1040.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: 'JF1041', name: 'JF1041', imageSrc: webImageNamed('Fabric/SuitFabric/JacquardFabrics900/VF1041.jpg')  , price:250 , company:''},
]
fabric_WaterResistant = [
    {optType:"Fabric", key: '280101', name: '280101', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280101.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280102', name: '280102', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280102.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280103', name: '280103', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280103.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280104', name: '280104', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280104.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280105', name: '280105', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280105.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280106', name: '280106', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280106.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280107', name: '280107', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280107.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280108', name: '280108', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280108.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280109', name: '280109', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280109.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280110', name: '280110', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280110.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280111', name: '280111', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280111.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280112', name: '280112', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280112.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280131', name: '280131', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280131.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280132', name: '280132', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280132.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280135', name: '280135', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280135.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280136', name: '280136', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280136.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280137', name: '280137', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280137.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280138', name: '280138', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280138.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280139', name: '280139', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280139.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280140', name: '280140', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280140.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280141', name: '280141', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280141.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280142', name: '280142', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280142.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280143', name: '280143', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280143.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280146', name: '280146', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280146.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280147', name: '280147', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280147.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280148', name: '280148', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280148.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280150', name: '280150', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280150.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280154', name: '280154', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280154.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280155', name: '280155', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280155.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280156', name: '280156', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280156.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280159', name: '280159', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280159.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280160', name: '280160', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280160.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280161', name: '280161', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280161.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280165', name: '280165', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280165.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280166', name: '280166', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280166.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280170', name: '280170', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280170.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280180', name: '280180', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280180.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280181', name: '280181', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280181.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280182', name: '280182', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280182.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280183', name: '280183', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280183.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280184', name: '280184', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280184.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280212', name: '280212', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280212.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280213', name: '280213', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280213.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280214', name: '280214', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280214.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280215', name: '280215', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280215.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280216', name: '280216', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280216.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280218', name: '280218', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280218.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280219', name: '280219', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280219.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280220', name: '280220', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280220.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280321', name: '280321', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280321.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280322', name: '280322', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280322.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280323', name: '280323', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280323.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280324', name: '280324', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280324.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280325', name: '280325', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280325.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280326', name: '280326', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280326.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280327', name: '280327', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280327.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280328', name: '280328', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280328.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280329', name: '280329', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280329.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280330', name: '280330', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280330.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280431', name: '280431', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280431.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280432', name: '280432', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280432.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280433', name: '280433', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280433.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280434', name: '280434', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280434.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280435', name: '280435', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280435.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280436', name: '280436', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280436.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280437', name: '280437', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280437.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280438', name: '280438', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280438.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280541', name: '280541', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280541.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280542', name: '280542', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280542.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280543', name: '280543', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280543.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280544', name: '280544', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280544.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280545', name: '280545', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280545.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280546', name: '280546', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280546.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280547', name: '280547', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280547.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280548', name: '280548', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280548.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280549', name: '280549', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280549.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280550', name: '280541', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280541.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280651', name: '280651', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280651.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280653', name: '280653', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280653.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280654', name: '280654', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280654.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280655', name: '280655', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280655.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280656', name: '280656', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280656.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280657', name: '280657', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280657.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280658', name: '280658', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280658.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280659', name: '280659', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280659.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280762', name: '280762', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280762.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280764', name: '280764', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280764.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280765', name: '280765', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280765.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280766', name: '280766', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280766.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280767', name: '280767', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280767.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280768', name: '280768', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280768.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280769', name: '280769', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280769.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280871', name: '280871', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280871.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280872', name: '280872', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280872.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280873', name: '280873', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280873.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280874', name: '280874', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280874.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280875', name: '280875', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280875.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280876', name: '280876', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280876.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280877', name: '280877', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280877.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280878', name: '280878', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280878.jpg')  , price:250 , company:''},
    {optType:"Fabric", key: '280880', name: '280880', imageSrc: webImageNamed('Fabric/SuitFabric/WateResistant900/280880.jpg')  , price:250 , company:''},
]
fabric_TuxedoContrast = [
    {optType:"Fabric", key: 'BlackSatin', name: 'BlackSatin', imageSrc: webImageNamed('Fabric/TuxedoContrast/BlackSatin.jpg')  , price:100 , company:''},
    {optType:"Fabric", key: 'IvorySatin', name: 'IvorySatin', imageSrc: webImageNamed('Fabric/TuxedoContrast/IvorySatin.jpg')  , price:100 , company:''},
    {optType:"Fabric", key: 'NavySatin', name: 'NavySatin', imageSrc: webImageNamed('Fabric/TuxedoContrast/NavySatin.jpeg')  , price:100 , company:''},
    {optType:"Fabric", key: 'WhiteSatin', name: 'WhiteSatin', imageSrc: webImageNamed('Fabric/TuxedoContrast/WhiteSatin.jpg')  , price:100 , company:''},
]
fabric_Buttons = [
    { optType: "Buttons", key: '1', name: '1', imageSrc: webImageNamed('Fabric/SuitButton/1.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '2', name: '2', imageSrc: webImageNamed('Fabric/SuitButton/2.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '3', name: '3', imageSrc: webImageNamed('Fabric/SuitButton/3.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '4', name: '4', imageSrc: webImageNamed('Fabric/SuitButton/4.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '5', name: '5', imageSrc: webImageNamed('Fabric/SuitButton/5.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '6', name: '6', imageSrc: webImageNamed('Fabric/SuitButton/6.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '7', name: '7', imageSrc: webImageNamed('Fabric/SuitButton/7.jpg'), price: null, company: '' },
    { optType: "Buttons", key: '8', name: '8', imageSrc: webImageNamed('Fabric/SuitButton/8.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B01', name: 'B01', imageSrc: webImageNamed('Fabric/SuitButton/B01.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B02', name: 'B02', imageSrc: webImageNamed('Fabric/SuitButton/B02.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B03', name: 'B03', imageSrc: webImageNamed('Fabric/SuitButton/B03.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B04', name: 'B04', imageSrc: webImageNamed('Fabric/SuitButton/B04.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B05', name: 'B05', imageSrc: webImageNamed('Fabric/SuitButton/B05.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B06', name: 'B06', imageSrc: webImageNamed('Fabric/SuitButton/B06.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B07', name: 'B07', imageSrc: webImageNamed('Fabric/SuitButton/B07.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B08', name: 'B08', imageSrc: webImageNamed('Fabric/SuitButton/B08.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B09', name: 'B09', imageSrc: webImageNamed('Fabric/SuitButton/B09.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B10', name: 'B10', imageSrc: webImageNamed('Fabric/SuitButton/B10.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B11', name: 'B11', imageSrc: webImageNamed('Fabric/SuitButton/B11.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B12', name: 'B12', imageSrc: webImageNamed('Fabric/SuitButton/B12.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B13', name: 'B13', imageSrc: webImageNamed('Fabric/SuitButton/B13.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B14', name: 'B14', imageSrc: webImageNamed('Fabric/SuitButton/B14.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B15', name: 'B15', imageSrc: webImageNamed('Fabric/SuitButton/B15.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B16', name: 'B16', imageSrc: webImageNamed('Fabric/SuitButton/B16.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B17', name: 'B17', imageSrc: webImageNamed('Fabric/SuitButton/B17.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B18', name: 'B18', imageSrc: webImageNamed('Fabric/SuitButton/B18.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B19', name: 'B19', imageSrc: webImageNamed('Fabric/SuitButton/B19.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B20', name: 'B20', imageSrc: webImageNamed('Fabric/SuitButton/B20.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B21', name: 'B21', imageSrc: webImageNamed('Fabric/SuitButton/B21.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B22', name: 'B22', imageSrc: webImageNamed('Fabric/SuitButton/B22.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B23', name: 'B23', imageSrc: webImageNamed('Fabric/SuitButton/B23.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B24', name: 'B24', imageSrc: webImageNamed('Fabric/SuitButton/B24.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B25', name: 'B25', imageSrc: webImageNamed('Fabric/SuitButton/B25.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B26', name: 'B26', imageSrc: webImageNamed('Fabric/SuitButton/B26.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B27', name: 'B27', imageSrc: webImageNamed('Fabric/SuitButton/B27.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B28', name: 'B28', imageSrc: webImageNamed('Fabric/SuitButton/B28.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B29', name: 'B29', imageSrc: webImageNamed('Fabric/SuitButton/B29.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B30', name: 'B30', imageSrc: webImageNamed('Fabric/SuitButton/B30.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B31', name: 'B31', imageSrc: webImageNamed('Fabric/SuitButton/B31.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B32', name: 'B32', imageSrc: webImageNamed('Fabric/SuitButton/B32.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B33', name: 'B33', imageSrc: webImageNamed('Fabric/SuitButton/B33.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B34', name: 'B34', imageSrc: webImageNamed('Fabric/SuitButton/B34.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B35', name: 'B35', imageSrc: webImageNamed('Fabric/SuitButton/B35.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B36', name: 'B36', imageSrc: webImageNamed('Fabric/SuitButton/B36.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B37', name: 'B37', imageSrc: webImageNamed('Fabric/SuitButton/B37.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B38', name: 'B38', imageSrc: webImageNamed('Fabric/SuitButton/B38.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B46', name: 'B46', imageSrc: webImageNamed('Fabric/SuitButton/B46.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B47', name: 'B47', imageSrc: webImageNamed('Fabric/SuitButton/B47.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B48', name: 'B48', imageSrc: webImageNamed('Fabric/SuitButton/B48.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B49', name: 'B49', imageSrc: webImageNamed('Fabric/SuitButton/B49.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'B50', name: 'B50', imageSrc: webImageNamed('Fabric/SuitButton/B50.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB39', name: 'HB39', imageSrc: webImageNamed('Fabric/SuitButton/HB39.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB40', name: 'HB40', imageSrc: webImageNamed('Fabric/SuitButton/HB40.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB41', name: 'HB41', imageSrc: webImageNamed('Fabric/SuitButton/HB41.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB42', name: 'HB42', imageSrc: webImageNamed('Fabric/SuitButton/HB42.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB43', name: 'HB43', imageSrc: webImageNamed('Fabric/SuitButton/HB43.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB44', name: 'HB44', imageSrc: webImageNamed('Fabric/SuitButton/HB44.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'HB45', name: 'HB45', imageSrc: webImageNamed('Fabric/SuitButton/HB45.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'JRY237', name: 'JRY237', imageSrc: webImageNamed('Fabric/SuitButton/JRY237.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'JRY238', name: 'JRY238', imageSrc: webImageNamed('Fabric/SuitButton/JRY238.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'JRY248', name: 'JRY248', imageSrc: webImageNamed('Fabric/SuitButton/JRY248.jpg'), price: null, company: '' },
    { optType: "Buttons", key: 'JRY251', name: 'JRY351', imageSrc: webImageNamed('Fabric/SuitButton/JRY351.jpg'), price: null, company: '' },
]



fabricImageForSelectedKey = (tone, key) => {
    let opts = null
    switch (tone) {

        // fabric_AllSeasons: 'fabric_AllSeasons',
        // fabric_FallWinter: 'fabric_FallWinter',
        // fabric_SpringSummer: 'fabric_SpringSummer',
        // fabric_JacquardFabrics: 'fabric_JacquardFabrics',
        // fabric_WaterResistant: 'fabric_WaterResistant',
        // fabric_TuxedoContrast: 'fabric_TuxedoContrast'


        // case this.fabric_AllSeasons: opts = this.fabric_AllSeasons; break;

        case this.fabric_AllSeasons1: opts = this.fabric_AllSeasons1; break;
        case this.fabric_AllSeasons2: opts = this.fabric_AllSeasons2; break;
        case this.fabric_AllSeasons3: opts = this.fabric_AllSeasons3; break;
        case this.fabric_AllSeasons4: opts = this.fabric_AllSeasons4; break;


        case this.fabric_FallWinter: opts = this.fabric_FallWinter; break;
        case this.fabric_SpringSummer: opts = this.fabric_SpringSummer; break;
        case this.fabric_JacquardFabrics: opts = this.fabric_JacquardFabrics; break;
        case this.fabric_WaterResistant: opts = this.fabric_WaterResistant; break;
        case this.fabric_TuxedoContrast: opts = this.fabric_TuxedoContrast; break;
        default: opts = this.fabric_AllSeasons1; break;
    }
    let thisObj = opts.filter((obj) => {
        return obj.key === key
    })
    return webImageNamed(thisObj[0].imageSrc)
}


optsForTone = (tone) => {
    switch (tone) {
        case this.TONE_KEYS.ChooseForMe: return []; break;
        // case this.TONE_KEYS.AllSeasons: return this.fabric_AllSeasons; break;

        case this.TONE_KEYS.AllSeasons: return this.fabric_AllSeasons1; break;
        case this.TONE_KEYS.AllSeasonsPremium: return this.fabric_AllSeasonPremium; break;
        case this.TONE_KEYS.FabricMerinoWool: return this.fabric_MerinoWool; break;
        // case this.TONE_KEYS.AllSeasons4: return this.fabric_AllSeasons4; break;


        // case this.TONE_KEYS.FallWinter: return this.fabric_FallWinter; break;
        // case this.TONE_KEYS.SpringSummer: return this.fabric_SpringSummer; break;
        // case this.TONE_KEYS.JacquardFabrics: return this.fabric_JacquardFabrics; break;
        // case this.TONE_KEYS.WaterResistant: return this.fabric_WaterResistant; break;
        // case this.TONE_KEYS.TuxedoContrast: return this.fabric_TuxedoContrast; break;
        default: return this.fabric_AllSeasons1;
    }
}

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

        let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.AllSeasons

        let opts = this.optsForTone(thisTone)


        console.log('OPTS NOW =', opts)
        console.log(opts)


        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }








    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }





    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_FrontButtons
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_FrontButtons.imageSrc) {
                mainImage = this.state.selected_FrontButtons.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric) {
                if (this.state.selected_Fabric.imageSrc) {
                    fabricImage = this.state.selected_Fabric.imageSrc
                }
            }

            let styleName = this.state.productType + "-" + makeid(3)

            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }


            let allSelelected = [this.state.selected_FrontButtons, this.state.selected_Lapel, this.state.selected_Pockets,
            this.state.selected_Bottom, this.state.selected_BackStrap, this.state.selected_VestBack, this.state.selected_Tone, this.state.selected_Fabric, this.state.selected_FabricLining,]


            let finalPrice = this.basePrice
            let addedPriceObjs = []
            let addOnPrice = 0

            allSelelected.forEach(asel => {
                if (asel != null) {
                    if (asel.price) {
                        finalPrice += asel.price
                        addOnPrice += asel.price
                        addedPriceObjs.push({ type: asel.optType, name: asel.name, price: asel.price })
                    }
                }

            })



            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_FrontButtons = ")
            console.log(this.state.selected_FrontButtons)

            let style = {
                frontButtons: this.state.selected_FrontButtons,
                lapel: this.state.selected_Lapel,
                // lapelWidth: this.state.selected_LapelWidth,
                pockets: this.state.selected_Pockets,
                bottom: this.state.selected_Bottom,
                backStrap: this.state.selected_BackStrap,
                // monogram: this.state.selected_Monogram,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric,
                fabricLining: this.state.selected_FabricLining,
                vestBack: this.state.selected_VestBack,
                vestBackLining: this.state.selected_VestBackLining,
                buttons: this.state.selected_Buttons,
            }


            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]
                let val = ms[1].name


                description += ' ' + type + ':' + val
                if (ms[1].customInput) {
                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)) {
                    description += ""
                } else {
                    description += ","
                }


            })

            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol, this.basePrice,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)
            // prodObj.key = 'id' + (new Date()).getTime();

            // // let prodObj = {
            // //     key: uniqId,
            // //     styleName:  styleName,
            // //     frontImage: mainImage,
            // //     fabricImage: fabricImage,
            // //     description: description,
            // //     type: this.state.productType,
            // //     basePrice: this.basePrice,
            // //     price: finalPrice,
            // //     addedPriceObjs: addedPriceObjs,
            // //     selectedSelections: style
            // // }

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]
            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map((obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric') {
                    fabricAndToneMaterials.push("Fabric - " + obj.name + (obj.customInput ? `<${obj.customInput}>` : ""))
                    return null
                }

                if (obj.optType.toLowerCase() === 'tone') {
                    return null
                    // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                }

                return {
                    "key": obj.optType,
                    "value": obj.name + (obj.customInput ? `<${obj.customInput}>` : ""),
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null,
                    "isConciergeSelection": true
                }
            })

            concToTwSelections = concToTwSelections.filter((obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials


            prod.styleSelections = concToTwSelections
            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

            // selected_FrontButtons : frontButtons
            // selected_Lapel : lapel
            // selected_LapelWidth : lapelWidth
            // selected_Pockets : pockets
            // selected_Bottom : bottom
            // selected_SleeveButtons : sleeveButtons
            // selected_Lining : lining
            // selected_Monogram : monogram
            // selected_Tone : tone
            // selected_Fabric : fabric

            // ns[stepKey] = itemKey

            // console.log("ITEM KEY =" + itemKey)
            // let firstOPT = this.frontButtons_Opts.filter( opt => { return (opt.key === itemKey)})


            // console.log("FIRST OPT")
            // console.log(firstOPT)


            switch (stepKey) {
                case this.STEP_KEYS.frontButtons: ns.selected_FrontButtons = this.frontButtons_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.lapel: ns.selected_Lapel = this.lapel_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                // case this.STEP_KEYS.lapelWidth: ns.selected_LapelWidth = this.lapelWidth_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.pockets: ns.selected_Pockets = this.pockets_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.bottom: ns.selected_Bottom = this.bottom_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.backStrap: ns.selected_BackStrap = this.backStrap_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter( opt => {return opt.key === itemKey})[0] ; if (customInput && customInput !== ""){ ns.selected_Monogram["customInput"] = customInput } ; break;
                case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.fabricLining: ns.selected_FabricLining = this.fabricLining_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.vestBack: ns.selected_VestBack = this.vestBack_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.vestBackLining: ns.selected_VestBackLining = this.vestBackLiningFabricOpts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.buttons: ns.selected_Buttons = this.buttons_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    waistWizard = () => {


        // selected_FrontButtons : frontButtons
        // selected_Lapel : lapel
        // selected_LapelWidth : lapelWidth
        // selected_Pockets : pockets
        // selected_Bottom : bottom
        // selected_SleeveButtons : sleeveButtons
        // selected_Lining : lining
        // selected_Monogram : monogram
        // selected_Tone : tone
        // selected_Fabric : fabric


        // let productStep = this.productStep()
        let frontButtons_Step = this.frontButtons_Step()
        let lapel_Step = this.lapel_Step()
        // let lapelWidth_Step = this.lapelWidth_Step()
        let pockets_Step = this.pockets_Step()
        let bottom_Step = this.bottom_Step()
        let backStrap_Step = this.backStrap_Step()
        // let monogram_Step = this.monogram_Step()
        let toneStep = this.toneStep()
        let fabricStep = this.fabricStep()
        let fabricLining_Step = this.fabricLining_Step()

        let buttons_Step = this.buttons_Step()

        let vestBack_Step = this.vestBack_Step()
        let vestBackLining_Step = this.vestBackLining_Step()

        let isLiningFabricNeeded = false
        if (this.state.selected_VestBack){
            if (this.state.selected_VestBack.key === vestBack_Step.opts[1].key) {
                isLiningFabricNeeded = true
            }
        }


        let isFabricSelectionNeeded = true
        if (this.state.selected_Tone){
            if (this.state.selected_Tone.key === this.TONE_KEYS.ChooseForMe) {
                isFabricSelectionNeeded = false
            }
        }


        let title = "Let's Create a WaistCoat Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            {/* <div ref={this.mainStepperDiv}></div> */}
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :
                <StepWizard className=' fadeIn'>
                    {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
                    {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12wjkjwb3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step>}

                    <Step name={frontButtons_Step.name} skipable={frontButtons_Step.skipable ?? false} stepKey={frontButtons_Step.key} exitingSelectedKey={this.state.selected_FrontButtons ? this.state.selected_FrontButtons.key : null} opts={frontButtons_Step.opts} isFirst={true} isPhotoBox={frontButtons_Step.isPhotoBox} photoRatio={frontButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={frontButtons_Step.boldText ?? false} boldText={frontButtons_Step.boldText ?? false} ></Step>
                    <Step name={lapel_Step.name} skipable={lapel_Step.skipable ?? false} stepKey={lapel_Step.key} exitingSelectedKey={this.state.selected_Lapel ? this.state.selected_Lapel.key : null} opts={lapel_Step.opts} isFirst={false} isPhotoBox={lapel_Step.isPhotoBox} photoRatio={lapel_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={lapel_Step.boldText ?? false} boldText={lapel_Step.boldText ?? false} ></Step>
                    {/* <Step name={lapelWidth_Step.name}    skipable={lapelWidth_Step.skipable ?? false} stepKey={lapelWidth_Step.key }         exitingSelectedKey={this.state.selected_LapelWidth ? this.state.selected_LapelWidth.key    : null }       opts={lapelWidth_Step.opts} isFirst={false} isPhotoBox={lapelWidth_Step.isPhotoBox} photoRatio={lapelWidth_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lapelWidth_Step.boldText ?? false}  boldText={lapelWidth_Step.boldText ?? false} ></Step>                */}
                    <Step name={pockets_Step.name} skipable={pockets_Step.skipable ?? false} stepKey={pockets_Step.key} exitingSelectedKey={this.state.selected_Pockets ? this.state.selected_Pockets.key : null} opts={pockets_Step.opts} isFirst={false} isPhotoBox={pockets_Step.isPhotoBox} photoRatio={pockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pockets_Step.boldText ?? false} boldText={pockets_Step.boldText ?? false} ></Step>
                    <Step name={bottom_Step.name} skipable={bottom_Step.skipable ?? false} stepKey={bottom_Step.key} exitingSelectedKey={this.state.selected_Bottom ? this.state.selected_Bottom.key : null} opts={bottom_Step.opts} isFirst={false} isPhotoBox={bottom_Step.isPhotoBox} photoRatio={bottom_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={bottom_Step.boldText ?? false} boldText={bottom_Step.boldText ?? false} ></Step>
                    <Step name={vestBack_Step.name}        skipable={vestBack_Step.skipable ?? false} stepKey={vestBack_Step.key }                 exitingSelectedKey={this.state.selected_VestBack ? this.state.selected_VestBack.key        : null }           opts={vestBack_Step.opts} isFirst={false} isPhotoBox={vestBack_Step.isPhotoBox} photoRatio={vestBack_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={vestBack_Step.boldText ?? false}  boldText={vestBack_Step.boldText ?? false} ></Step>

                    {/* {
                        isLiningFabricNeeded ?
                        // <Step name={vestBackLining_Step.name}   skipable={vestBackLining_Step.skipable ?? false} stepKey={vestBackLining_Step.key }    exitingSelectedKey={this.state.selected_VestBackLining ? this.state.selected_VestBackLining.key   : null }     opts={vestBackLining_Step.opts} isFirst={false} isPhotoBox={vestBackLining_Step.isPhotoBox} photoRatio={vestBackLining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={isLiningFabricNeeded} paddingLess={vestBackLining_Step.boldText ?? false}  boldText={vestBackLining_Step.boldText ?? false} ></Step>
                        <Step name={vestBackLining_Step.name}   skipable={vestBackLining_Step.skipable ?? false} stepKey={vestBackLining_Step.key }    exitingSelectedKey={this.state.selected_VestBackLining ? this.state.selected_VestBackLining.key   : null }     opts={vestBackLining_Step.opts} isFirst={false} isPhotoBox={vestBackLining_Step.isPhotoBox} photoRatio={vestBackLining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={vestBackLining_Step.boldText ?? false}  boldText={vestBackLining_Step.boldText ?? false} ></Step>

                        : null
                    } */}
                    
                    <Step name={backStrap_Step.name} skipable={backStrap_Step.skipable ?? false} stepKey={backStrap_Step.key }   exitingSelectedKey={this.state.selected_BackStrap ? this.state.selected_BackStrap.key : null }    opts={backStrap_Step.opts} isFirst={false} isPhotoBox={backStrap_Step.isPhotoBox} photoRatio={backStrap_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={backStrap_Step.boldText ?? false}  boldText={backStrap_Step.boldText ?? false} ></Step>


                    {/* <Step name={monogram_Step.name}   needsCustomInput={true}   skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key }             exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key      : null }         opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={monogram_Step.boldText ?? false}  boldText={monogram_Step.boldText ?? false} ></Step> */}
                    <Step name={toneStep.name} skipable={toneStep.skipable ?? false} stepKey={toneStep.key} exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key : null} opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={toneStep.boldText ?? false} boldText={toneStep.boldText ?? false} ></Step>                    
                    {
                        isFabricSelectionNeeded ?
                            <Step name={fabricStep.name} skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key : null} opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricStep.boldText ?? false} boldText={fabricStep.boldText ?? false} ></Step>
                        : null
                    }
                    {
                        isFabricSelectionNeeded ?
                            <Step name={fabricLining_Step.name} skipable={fabricLining_Step.skipable ?? false} stepKey={fabricLining_Step.key} exitingSelectedKey={this.state.selected_FabricLining ? this.state.selected_FabricLining.key : null} opts={fabricLining_Step.opts} isFirst={false} isPhotoBox={fabricLining_Step.isPhotoBox} photoRatio={fabricLining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricLining_Step.boldText ?? false} boldText={fabricLining_Step.boldText ?? false} ></Step>
                        : null
                    }
                    
                    
                    <Step name={buttons_Step.name} skipable={buttons_Step.skipable ?? false} stepKey={buttons_Step.key} exitingSelectedKey={this.state.selected_Buttons ? this.state.selected_Buttons.key : null} opts={buttons_Step.opts} isFirst={false} isPhotoBox={buttons_Step.isPhotoBox} photoRatio={buttons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={buttons_Step.boldText ?? false} boldText={buttons_Step.boldText ?? false} ></Step>

                    {/* <Step name={vestBack_Step.name}        skipable={vestBack_Step.skipable ?? false} stepKey={vestBack_Step.key }                 exitingSelectedKey={this.state.selected_VestBack ? this.state.selected_VestBack.key        : null }           opts={vestBack_Step.opts} isFirst={false} isPhotoBox={vestBack_Step.isPhotoBox} photoRatio={vestBack_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={!isLiningFabricNeeded} paddingLess={vestBack_Step.boldText ?? false}  boldText={vestBack_Step.boldText ?? false} ></Step> */}


                </StepWizard>
            }
            {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
        </div>
        )
    }




    render() {
        return (
            this.waistWizard()
        )
    }

}


export default WizardForWaistCoat
