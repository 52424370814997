import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Columns, Heading, Dropdown, Input } from 'react-bulma-components/dist'
import { Formik, Form, Field } from 'formik'
import { getCurrentCustomer, saveCurrentCustomer } from '../utilities/Login'
import { Order } from '../utilities/classes'
import cogoToast from 'cogo-toast'
import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-datepicker'






const OrderActionForm = (props) => {


    const [status, setStatus] = useState(props.order.status ?? 'PENDING')
    const [paymentStatus, setPaymentStatus] = useState(props.order.isPaid)

    const [displayNote, setDisplayNote] = useState(props.order.conciergeDisplayNote ?? '')

    const [refundAmount, setRefundAmount] = useState(props.order.refundedAmount ?? 0)
    const [customPrice, setCustomPrice] = useState(props.order.price ?? 0)

    const [deliveryDate, setDeliveryDate] = useState(props.order.deliveryDate ?? null)

    const [isSaving, setIsSaving] = useState(false)



    const refundAmountChanged = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setRefundAmount(e.target.value)
        }
    }

    const customPriceChanged = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setCustomPrice(e.target.value)
        }
    }

    const noteChanged = (e) => {
        setDisplayNote(e.target.value)
    }

    const statusChanged = (e) => {
        setStatus(e)
    }

    const paymentStatusChanged = (e) => {
        let ip = false
        if (e === "PAID"){
            ip = true
        }
        setPaymentStatus(ip)
    }

    const deliveryDateChanged = (date) => {

        let bookingDate = props.order.bookingDate

        let bookingDateTime = bookingDate.getTime()
        let selectedDateTime = date.getTime()
        if (selectedDateTime < bookingDateTime) {
            cogoToast.error('Delivery Date cannot be earlier than Booking Date.')
            return
        }

        setDeliveryDate(date)
    }

    const orderSaved = () => {
        setIsSaving(true)

        let orderCopy = Order.copyFrom(props.order)

        orderCopy.status = status
        orderCopy.conciergeDisplayNote = displayNote
        orderCopy.refundedAmount = refundAmount
        orderCopy.deliveryDate = deliveryDate
        orderCopy.isPaid = paymentStatus

        if (status === "CANCELLED") {
            orderCopy.isCancelled = true
            orderCopy.status = props.order.status
        } else {
            orderCopy.isCancelled = false
        }

        if (refundAmount > 0) {
            orderCopy.isRefunded = true
        } else {
            orderCopy.isRefunded = false
        }

        if (customPrice > 0) {
            orderCopy.price = customPrice
        } 

        orderCopy.update((succ, errMsg) => {
            if (succ) {
                cogoToast.success('Order Updated')
                props.orderUpdated(orderCopy)
            } else {
                cogoToast.error('Could not update order. Please check valid entries, active internet and try again.')
                console.log(errMsg)
            }
            setIsSaving(false)
        })

    }


    return (

        <div className='is-two-fifths  pad20 fadeIn'>
            <br />

            <Heading className=' has-text-grey' size={6}> ORDER #{props.order.orderNo}</Heading>

            <div className='field marginTop20'>
                {
                    props.order.isCancelled ?
                        <Heading size={6} className="type74 has-text-danger"> is set Cancelled</Heading>
                        :
                        null
                }

                <label className="label is-size-7 marginTop10">Status</label>
                <Dropdown right={false} className='is-small' value={status} onChange={statusChanged} color="grey" label={'Actions'}>
                    <Dropdown.Item value="PENDING" >
                        PENDING
                    </Dropdown.Item>
                    <Dropdown.Item value="DELIVERED">
                        DELIVERED
                    </Dropdown.Item>
                    <Dropdown.Item value="CANCELLED">
                        CANCELLED
                    </Dropdown.Item>
                </Dropdown>
            </div>


            <div className='field marginTop20'>
                <label className="label is-size-7 marginTop10">Payment Status</label>
                <Dropdown right={false} className='is-small' value={paymentStatus ? "PAID" : "PENDING"} onChange={paymentStatusChanged} color="grey" label={'Actions'}>
                    <Dropdown.Item value="PENDING" >
                        PENDING
                    </Dropdown.Item>
                    <Dropdown.Item value="PAID">
                        PAID
                    </Dropdown.Item>
                </Dropdown>
            </div>

            <div className='field marginTop20'>
                <label className="label is-size-7 marginTop10">Set Custom Pending Price</label>
                <input className='input' name='customPrice' type="number" min={0} max={1000000} value={customPrice} onChange={customPriceChanged} />
            </div>



            <div className='field marginTop20'>
                <label className="label is-size-7 marginTop10">Expected Delivery Date</label>
                <div className='field '>

                    <DatePicker onFocus={e => e.target.blur()} name='deliveryDate' defaultValue={deliveryDate} dateFormat="MMM/dd/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='mm/dd/yyyy' selected={deliveryDate ?? null} onChange={date => deliveryDateChanged(date)} />
                </div>
            </div>

            <div className='field marginTop20'>
                <label className="label is-size-7 marginTop10">Display note</label>
                <textarea name={"displayNote"} placeholder={""} type='input' value={displayNote} onChange={noteChanged} className='input textarea type65' />
            </div>

            <div className='field marginTop20'>
                <label className="label is-size-7 marginTop10">Show Refund Amount</label>
                <input className='input' name='refundAmount' type="number" min={0} max={1000000} value={refundAmount} onChange={refundAmountChanged} />
            </div>

            <div className='control is-expanded marginTop20'>
                <Button disabled={isSaving} className='is-black' loading={isSaving} type='submit' onClick={orderSaved} > Update </Button>
            </div>



        </div>


    )
}

export default OrderActionForm