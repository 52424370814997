import React, { Component } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from './ColumnAux'
import LoadingAux from './LoadingAux'
import ChooseBox from './ChooseBox'
import WizardForJacket from '../clientInfo/WizardForJacket'
import WizardForShirt from '../clientInfo/WizardForShirt'
import WizardForSuit from '../clientInfo/WizardForSuit'
import WizardForPant from '../clientInfo/WizardForPant'
import WizardForTuxedo from '../clientInfo/WizardForTuxedo'

import WizardForWaistCoat from '../clientInfo/WizardForWaistCoat'
import WizardForOverCoat from '../clientInfo/WizardForOverCoat'
import { imageNamed, scrollToRef, PRODUCT_TYPE } from '../utilities/utils'
import { availableProducts } from '../clientInfo/clientInfo'
import WizardForKurta from '../clientInfo/WizardForKurta'




// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class StyleChooserWizard2 extends Component {

    STEP_KEYS = {
        productType: '',
    }


    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }



    state = {
        productType: null,
        uploadImage: null,
        selected_Product: null,
        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: null
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.productType){
            ns.productType = this.props.productType
        }
        if (this.props.editingProduct) {

            console.log("EDITING PRODUCT DETAILS")

            console.log(this.props.editingProduct)
            let existingProd = { ...this.props.editingProduct }
            ns.editingProduct = existingProd
            ns.productType = existingProd.type
        }
        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }


    // productOpts = [
    //     { key: PRODUCT_TYPE.suit , name: 'Suit', imageSrc: null},
    //     { key: PRODUCT_TYPE.shirt, name: 'Shirt', imageSrc: null },
    //     { key: PRODUCT_TYPE.jacket, name: 'Jacket', imageSrc: null },
    //     { key: PRODUCT_TYPE.shoe, name: 'Shoe', imageSrc: null },
    // ]







    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    };

    // scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth',
    //       })
    // }


    donePressed = (prodObj) => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)

            


            // console.log('Sending Style Object -', prodObj)

            this.props.isDone('created', prodObj)

        }
    }

    stepItemSelected = (stepKey, itemKey) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            switch (stepKey) {
                case this.STEP_KEYS.productType: ns.selected_Product = itemKey; break;
                default: 
                
                ;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }

    productChoiceMade = (name, code) => {
        console.log('Product Choice Made. Code =', code)
        if (this._mounted) {
            let ns = { ...this.state }
            ns.productType = code
            this.setState(ns)
        }
    }

    productChooser = () => {


        let choices = [
            // { name: 'Jacket', code: PRODUCT_TYPE.jacket },
            { name: 'Shirt', code: PRODUCT_TYPE.shirt },
            { name: 'Suit', code: PRODUCT_TYPE.suit },
            { name: 'Kurta', code: PRODUCT_TYPE.kurta },
            { name: 'Pant', code: PRODUCT_TYPE.pant },
            
            // { name: 'WaistCoat', code: PRODUCT_TYPE.waistCoat },
            // { name: 'OverCoat', code: PRODUCT_TYPE.overCoat },
        ]
        


        // if (availableProducts.includes(PRODUCT_TYPE.tuxedo)){
        //     choices.push(
        //         { name: 'Tuxedo', code: PRODUCT_TYPE.tuxedo }
        //     )
        // }


        return <div>
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>

            {/* <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '> */}
            <Box className='is-radiusless is-shadowless   myFlexContainer '>
                <ChooseBox choices={choices} choiceMade={this.productChoiceMade} />
            </Box>
        </div>
    }

    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }





        // productOpts = [
    //     { key: PRODUCT_TYPE.suit , name: 'Suit', imageSrc: null},
    //     { key: PRODUCT_TYPE.shirt, name: 'Shirt', imageSrc: null },
    //     { key: PRODUCT_TYPE.pant, name: 'Pant', imageSrc: null },
    //     { key: PRODUCT_TYPE.shoe, name: 'Shoe', imageSrc: null },
    // ]


    // suitWizard
    jacketWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForJacket productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }

    shirtWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForShirt productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }


    suitWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForSuit productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }


    kurtaWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForKurta productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }





    pantWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForPant productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }

    tuxedoWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForTuxedo productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }

    waistCoatWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :
                // show jacket wizard
                <WizardForWaistCoat productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />
            }
        </div>
        )
    }

    overcoatWizard = (showUploader = false) => {

        let title = "Let's Create a Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            <Button onClick={this.props.cancelPressed} className='delete type50 is-small has-text-light'></Button>
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :

                // show jacket wizard
                <WizardForOverCoat productType={this.state.productType} editingProduct={this.state.editingProduct} cancelPressed={this.props.cancelPressed} isDone={this.props.isDone} />

            }
        </div>
        )
    }


    currentScreen = () => {

        if (this.state.editingProduct) {
            switch (this.state.productType) {
                // case PRODUCT_TYPE.suit: return this.suitWizard(false);
                case PRODUCT_TYPE.jacket: return this.jacketWizard(false);
                case PRODUCT_TYPE.shirt: return this.shirtWizard(false);
                case PRODUCT_TYPE.suit: return this.suitWizard(false);
                case PRODUCT_TYPE.kurta: return this.kurtaWizard(false);
                case PRODUCT_TYPE.pant: return this.pantWizard(false);
                case PRODUCT_TYPE.waistCoat: return this.waistCoatWizard(false);
                case PRODUCT_TYPE.overCoat: return this.overcoatWizard(false);
                case PRODUCT_TYPE.tuxedo: return this.tuxedoWizard(false);
                default: break;
            }
        }


        if (this.state.productType) {

            switch (this.state.productType) {
                // case PRODUCT_TYPE.suit: return this.suitWizard(false);
                case PRODUCT_TYPE.jacket: return this.jacketWizard(false);
                case PRODUCT_TYPE.shirt: return this.shirtWizard(false);
                case PRODUCT_TYPE.suit: return this.suitWizard(false);
                case PRODUCT_TYPE.kurta: return this.kurtaWizard(false);
                case PRODUCT_TYPE.pant: return this.pantWizard(false);
                case PRODUCT_TYPE.waistCoat: return this.waistCoatWizard(false);
                case PRODUCT_TYPE.overCoat: return this.overcoatWizard(false);
                case PRODUCT_TYPE.tuxedo: return this.tuxedoWizard(false);

                default: break;
            }

            // if (this.state.selectedProduct === this.STYLE_START.suit) {
            //     return this.suitWizard()
            // } else if (this.state.selectedCreateOrUpload === this.STYLE_START.upload) {
            //     return this.suitWizard(true)
            // }
        }

        return this.productChooser()

        // if (this.state.selectedCreateOrUpload) {
        //     if (this.state.selectedCreateOrUpload === this.STYLE_START.create) {
        //         return this.suitWizard()
        //     } else if (this.state.selectedCreateOrUpload === this.STYLE_START.upload) {
        //         return this.suitWizard(true)
        //     }
        //     // SELECTION CASE REMAINING
        // }

        //SKiping Wizard Chooser Currently
        // return this.createUploadChooser()
    }

    render() {
        return (
            this.currentScreen()
        )
    }

}


export default StyleChooserWizard2
