import React from 'react'
import { Box, Columns, Button, Tag, Image } from 'react-bulma-components/dist';
import LoadingAux from './LoadingAux'
import { imageNamed, PRODUCT_TYPE } from '../utilities/utils'
import {dateToDateString} from '../utilities/helper'
import { isCustomPostPaid_AllProducts } from '../clientInfo/clientInfo';

const OrderBox = (props) => {

    const editPressed = () => {
        props.editPressed(props.order.objectId)
    }

    const makeDefaultPressed = () => {
        props.makeDefaultPressed(props.order.objectId)
    }

    const orderDescription = (ord) => {
        let isPaid = ord.isPaid ? 'Paid' : 'Pending Payment'
        let note = ord.conciergeDisplayNote ?? "NOTE:"
        let description = isPaid + '. \n' + note
        return description
    }


    const viewPressed = (order) => {
        props.viewPressed(order)
    }

    const orderBox = (order) => {

        //  id, orderNo, productsCount, bookingD, trialD, deliveryD, status

        return (
            <Box key={order.objectId} className='hoverShine noBreakWords generalCardBG'>
                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                        <p className='has-text-grey-light has-text-weight-medium  marginBottom5'> ORDER </p>
                        <p className='maxWrap165 has-text-weight-semibold'>#{order.orderNo} </p> 
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Payment </p>
                        {order.isPaid ? <Tag className="" > Paid {order.currency + order.price + ''}</Tag> :  <Tag className="is-warning is-light" >{order.currency + order.price + ''}</Tag>}  

                        {/* {order.isPaid ? <Tag className="is-info is-light" >Paid</Tag> :  <Tag className="is-warning is-light" >Pending</Tag>}   */}
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Booking </p>
                        <p>{ order.bookingDate ?  dateToDateString(order.bookingDate) : null}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Exp. Delivery </p>
                        <p>{ order.deliveryDate ? dateToDateString(order.deliveryDate) : null}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Status </p>
                        <Tag color="light" >{order.status}</Tag>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={() => { viewPressed(order) }}> View </Button>
                    </Columns.Column>
                </Columns>
            </Box>
        )
    }


    const orderBox2 = (order) => {
        return (
            <Box className=' is-radiusless noBreakWords layerGreyBG'>
                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                    <div className='  marginLeft10  marginBottom5 is-uppercase'># {order.orderNo}  </div>
                    <br />
                    <div className='  marginLeft20  marginBottom5 is-uppercase'> {order.isPaid ? 'Paid' : 'Pending Payment'}  </div>
                    <br />
                    <div className='  has-text-grey marginLeft20  marginBottom5 is-uppercase'> {order.conciergeDisplayNote ?? ""}  </div>
                    <div className='has-text-grey  marginBottom5'> Status </div>
                        <div className='is-small is-light' > {order.status} </div>
                        <div>Added {order.createdOn + ''}</div>
                    </Columns.Column>
                    {/* <Columns.Column className=''>
                        <br />
                        <div className=' has-text-weight-semibold has-text-grey marginLeft20  marginBottom5 is-uppercase'> {order.conciergeDisplayNote ?? ""}  </div>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <br />
                        <div className='has-text-grey  marginBottom5'> Status </div>
                        <div className='is-small is-light' > {order.status} </div>
                        <div>Added {order.createdOn + ''}</div>
                    </Columns.Column> */}
                    <Columns.Column className=''>
                        <Button className='is-small is-white marginTop10 marginLeft10' > VIEW  DETAILS </Button>
                        {/* <Button className='is-small is-white has-text-grey marginTop10 marginLeft10' > ADD DETAIL </Button>
                        <Button className='type45  no-decoration has-text-grey is-text marginTop10 marginLeft10' > REQUEST EDIT </Button> */}


                    </Columns.Column>


                </Columns>
            </Box>
        )
    }


    const productBox = (type, description, createdOn) => {
        return (
            <Box className=' is-radiusless noBreakWords layerGreyBG'>
                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                    <div className=' has-text-weight-semibold marginLeft10  marginBottom5 is-uppercase'> {type}  </div>
                        <div className='orderBoxContainer'>
                            <div className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                                <Image className='is-clipped ' src={imageNamed('double4')} size='1by1' />
                            </div>
                            <div className='  is-radiusless pad0 is-shadowless is-clipped styleBox80'>
                                <Image className='is-clipped ' src={imageNamed('fab_blue4')} size='1by1' />
                            </div>
                        </div>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <br />
                        <div>{description}</div>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <br />
                        <div className='has-text-grey  marginBottom5'> Status </div>
                        <div className='is-small is-light' > Ready to Send </div>
                        <div>Added {createdOn}</div>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-small is-white marginTop10 marginLeft10' > VIEW  DETAILS </Button>
                        <Button className='is-small is-white has-text-grey marginTop10 marginLeft10' > ADD DETAIL </Button>
                        <Button className='type45  no-decoration has-text-grey is-text marginTop10 marginLeft10' > REQUEST EDIT </Button>


                    </Columns.Column>


                </Columns>
            </Box>
        )
    }




    const paymentMethodCardToShow = () => {

        return orderBox(props.order)
    }

    return (
        paymentMethodCardToShow()
    )
}

export default OrderBox
